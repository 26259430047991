import { fetchCurrentUser } from "../../ducks/user.duck";
import {
  getDotsInfo,
  getDotsManagePayoutLink,
  getDotsPayoutLink,
  getDotsVerificationLink
} from "../../util/api";

const initialState = {
  fetchInProgress: false,
  info: null,
  fetchError: null,
  getLinkInProgress: false,
  getLinkError: null,
  getWithdrawInProgress: false,
  getWithdrawError: null,
  getManagePayoutInProgress: false,
  getManagePayoutError: null
};

const FETCH_INFO_REQUEST = "app/DotsPayoutPage/FETCH_INFO_REQUEST";
const FETCH_INFO_SUCCESS = "app/DotsPayoutPage/FETCH_INFO_SUCCESS";
const FETCH_INFO_ERROR = "app/DotsPayoutPage/FETCH_INFO_ERROR";

const GET_VERIFICATION_LINK_REQUEST = "app/DotsPayoutPage/GET_VERIFICATION_REQUEST";
const GET_VERIFICATION_LINK_SUCCESS = "app/DotsPayoutPage/GET_VERIFICATION_SUCCESS";
const GET_VERIFICATION_LINK_ERROR = "app/DotsPayoutPage/GET_VERIFICATION_ERROR";

const GET_WITHDRAW_LINK_REQUEST = "app/DotsPayoutPage/GET_WITHDRAW_REQUEST";
const GET_WITHDRAW_LINK_SUCCESS = "app/DotsPayoutPage/GET_WITHDRAW_SUCCESS";
const GET_WITHDRAW_LINK_ERROR = "app/DotsPayoutPage/GET_WITHDRAW_ERROR";

const GET_MANAGE_PAYOUT_LINK_REQUEST = "app/DotsPayoutPage/GET_MANAGE_PAYOUT_REQUEST";
const GET_MANAGE_PAYOUT_LINK_SUCCESS = "app/DotsPayoutPage/GET_MANAGE_PAYOUT_SUCCESS";
const GET_MANAGE_PAYOUT_LINK_ERROR = "app/DotsPayoutPage/GET_MANAGE_PAYOUT_LINK_ERROR";

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_INFO_REQUEST:
      return { ...state, fetchInProgress: true, fetchError: null };
    case FETCH_INFO_SUCCESS:
      return { ...state, fetchInProgress: false, info: payload };
    case FETCH_INFO_ERROR:
      return { ...state, fetchInProgress: false, fetchError: payload };
    case GET_VERIFICATION_LINK_REQUEST:
      return { ...state, getLinkInProgress: true, getLinkError: null };
    case GET_VERIFICATION_LINK_SUCCESS:
      return { ...state, getLinkInProgress: false };
    case GET_VERIFICATION_LINK_ERROR:
      return { ...state, getLinkInProgress: false, getLinkError: payload };
    case GET_WITHDRAW_LINK_REQUEST:
      return { ...state, getWithdrawInProgress: true, getWithdrawError: null };
    case GET_WITHDRAW_LINK_SUCCESS:
      return { ...state, getWithdrawInProgress: false };
    case GET_WITHDRAW_LINK_ERROR:
      return { ...state, getWithdrawInProgress: false, getWithdrawError: payload };
    case GET_MANAGE_PAYOUT_LINK_REQUEST:
      return { ...state, getManagePayoutInProgress: true, getManagePayoutError: null };
    case GET_MANAGE_PAYOUT_LINK_SUCCESS:
      return { ...state, getManagePayoutInProgress: false };
    case GET_MANAGE_PAYOUT_LINK_ERROR:
      return { ...state, getManagePayoutInProgress: false, getManagePayoutError: payload };
    default:
      return state;
  }
};

const fetchInfoRequest = () => ({ type: FETCH_INFO_REQUEST });
const fetchInfoSuccess = info => ({ type: FETCH_INFO_SUCCESS, payload: info });
const fetchInfoError = error => ({ type: FETCH_INFO_ERROR, payload: error });

const fetchInfo = () => async dispatch => {
  dispatch(fetchInfoRequest());
  try {
    const response = await getDotsInfo();
    dispatch(fetchInfoSuccess(response.data));
  } catch (e) {
    dispatch(fetchInfoError(storableError(e)));
  }
};

const getVerificationLinkRequest = () => ({ type: GET_VERIFICATION_LINK_REQUEST });
const getVerificationLinkSuccess = () => ({ type: GET_VERIFICATION_LINK_SUCCESS });
const getVerificationLinkError = error => ({ type: GET_VERIFICATION_LINK_ERROR, payload: error });

export const getVerificationLink = () => async dispatch => {
  dispatch(getVerificationLinkRequest());
  try {
    const response = await getDotsVerificationLink();
    dispatch(getVerificationLinkSuccess());
    return response.data;
  } catch (e) {
    dispatch(getVerificationLinkError(storableError(e)));
  }
};

const getWithdrawLinkRequest = () => ({ type: GET_WITHDRAW_LINK_REQUEST });
const getWithdrawLinkSuccess = () => ({ type: GET_WITHDRAW_LINK_SUCCESS });
const getWithdrawLinkError = error => ({ type: GET_WITHDRAW_LINK_ERROR, payload: error });

export const getWithdrawLink = () => async dispatch => {
  dispatch(getWithdrawLinkRequest());
  try {
    const response = await getDotsPayoutLink();
    dispatch(getWithdrawLinkSuccess());
    return response.data;
  } catch (e) {
    dispatch(getWithdrawLinkError(storableError(e)));
  }
};

const getManagePayoutLinkRequest = () => ({ type: GET_MANAGE_PAYOUT_LINK_REQUEST });
const getManagePayoutLinkSuccess = () => ({ type: GET_MANAGE_PAYOUT_LINK_SUCCESS });
const getManagePayoutLinkError = error => ({ type: GET_MANAGE_PAYOUT_LINK_ERROR, payload: error });

export const getManagePayoutLink = () => async dispatch => {
  dispatch(getManagePayoutLinkRequest());
  try {
    const response = await getDotsManagePayoutLink();
    dispatch(getManagePayoutLinkSuccess());
    return response.data;
  } catch (e) {
    dispatch(getManagePayoutLinkError(storableError(e)));
  }
};

export const loadData = () => async dispatch => {
  dispatch(fetchInfo());
};
