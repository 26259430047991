import { addMarketplaceEntities } from "../../ducks/marketplaceData.duck";
import { fetchCurrentUser } from "../../ducks/user.duck";
import { createImageVariantConfig } from "../../util/sdkLoader";
import { denormalisedResponseEntities } from "../../util/data";
import { storableError } from "../../util/errors";
import { showUserApi } from "../../util/api";

// ================ Action types ================ //

export const SET_INITIAL_STATE = "app/ProfilePage/SET_INITIAL_STATE";

export const SHOW_USER_REQUEST = "app/ProfilePage/SHOW_USER_REQUEST";
export const SHOW_USER_SUCCESS = "app/ProfilePage/SHOW_USER_SUCCESS";
export const SHOW_USER_ERROR = "app/ProfilePage/SHOW_USER_ERROR";

export const QUERY_LISTINGS_REQUEST = "app/ProfilePage/QUERY_LISTINGS_REQUEST";
export const QUERY_LISTINGS_SUCCESS = "app/ProfilePage/QUERY_LISTINGS_SUCCESS";
export const QUERY_LISTINGS_ERROR = "app/ProfilePage/QUERY_LISTINGS_ERROR";

export const QUERY_REVIEWS_REQUEST = "app/ProfilePage/QUERY_REVIEWS_REQUEST";
export const QUERY_REVIEWS_SUCCESS = "app/ProfilePage/QUERY_REVIEWS_SUCCESS";
export const QUERY_REVIEWS_ERROR = "app/ProfilePage/QUERY_REVIEWS_ERROR";

// ================ Reducer ================ //

const initialState = {
  userId: null,
  userListingRefs: [],
  userShowError: null,
  queryListingsError: null,
  reviews: [],
  queryReviewsError: null,
  user: null,
  showUserInProgress: false,
};

export default function profilePageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_STATE:
      return { ...initialState };
    case SHOW_USER_REQUEST:
      return { ...state, userShowError: null, userId: payload.userId, user: null, showUserInProgress: true };
    case SHOW_USER_SUCCESS:
      return { ...state, user: payload, showUserInProgress: false };
    case SHOW_USER_ERROR:
      return { ...state, userShowError: payload, showUserInProgress: false };

    case QUERY_LISTINGS_REQUEST:
      return {
        ...state,

        // Empty listings only when user id changes
        userListingRefs: payload.userId === state.userId ? state.userListingRefs : [],

        queryListingsError: null
      };
    case QUERY_LISTINGS_SUCCESS:
      return { ...state, userListingRefs: payload.listingRefs };
    case QUERY_LISTINGS_ERROR:
      return { ...state, userListingRefs: [], queryListingsError: payload };
    case QUERY_REVIEWS_REQUEST:
      return { ...state, queryReviewsError: null };
    case QUERY_REVIEWS_SUCCESS:
      return { ...state, reviews: payload };
    case QUERY_REVIEWS_ERROR:
      return { ...state, reviews: [], queryReviewsError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setInitialState = () => ({
  type: SET_INITIAL_STATE
});

export const showUserRequest = userId => ({
  type: SHOW_USER_REQUEST,
  payload: { userId }
});

export const showUserSuccess = user => ({
  type: SHOW_USER_SUCCESS,
  payload: user,
});

export const showUserError = e => ({
  type: SHOW_USER_ERROR,
  error: true,
  payload: e
});

export const queryListingsRequest = userId => ({
  type: QUERY_LISTINGS_REQUEST,
  payload: { userId }
});

export const queryListingsSuccess = listingRefs => ({
  type: QUERY_LISTINGS_SUCCESS,
  payload: { listingRefs }
});

export const queryListingsError = e => ({
  type: QUERY_LISTINGS_ERROR,
  error: true,
  payload: e
});

export const queryReviewsRequest = () => ({
  type: QUERY_REVIEWS_REQUEST
});

export const queryReviewsSuccess = reviews => ({
  type: QUERY_REVIEWS_SUCCESS,
  payload: reviews
});

export const queryReviewsError = e => ({
  type: QUERY_REVIEWS_ERROR,
  error: true,
  payload: e
});

// ================ Thunks ================ //

export const queryUserListings = (userId, config) => (dispatch, getState, sdk) => {
  dispatch(queryListingsRequest(userId));

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = "listing-card"
  } = config.layout.listingImage;
  const aspectRatio = aspectHeight / aspectWidth;

  return sdk.listings
    .query({
      author_id: userId,
      include: ["author", "images"],
      "fields.image": [`variants.${variantPrefix}`, `variants.${variantPrefix}-2x`],
      ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
      ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
      meta_hideOnSearch: false
    })
    .then(response => {
      // Pick only the id and type properties from the response listings
      const listingRefs = response.data.data.map(({ id, type }) => ({ id, type }));
      dispatch(addMarketplaceEntities(response));
      dispatch(queryListingsSuccess(listingRefs));
      return response;
    })
    .catch(e => dispatch(queryListingsError(storableError(e))));
};

export const queryUserReviews = userId => (dispatch, getState, sdk) => {
  sdk.reviews
    .query({
      subject_id: userId,
      state: "public",
      include: ["author", "author.profileImage"],
      "fields.image": ["variants.square-small", "variants.square-small2x"]
    })
    .then(response => {
      const reviews = denormalisedResponseEntities(response);
      dispatch(queryReviewsSuccess(reviews));
    })
    .catch(e => dispatch(queryReviewsError(e)));
};

export const showUser = username => async (dispatch, getState, sdk) => {
  dispatch(showUserRequest(username));
  try {
    const response = await showUserApi({
      username: username,
      include: ["profileImage"],
      "fields.image": ["variants.square-small", "variants.square-small2x"]
    });
    dispatch(addMarketplaceEntities(response));
    const [user] = denormalisedResponseEntities(response);
    dispatch(showUserSuccess(user));
    return user;
  } catch (e) {
    dispatch(showUserError(storableError(e)));
    throw e;
  }
};

export const loadData = (params, search, config) => async (dispatch, getState, sdk) => {
  const { username } = params;
  // Clear state so that previously loaded data is not visible
  // in case this page load fails.
  dispatch(setInitialState());
  const user = await dispatch(showUser(username));
  await dispatch(fetchCurrentUser());
  const userId = user.id.uuid;

  return Promise.all([
    dispatch(queryUserListings(userId, config)),
    dispatch(queryUserReviews(userId))
  ]);
};
