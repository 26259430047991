// These helpers are calling this template's own server-side routes
// so, they are not directly calling Marketplace API or Integration API.
// You can find these api endpoints from 'server/api/...' directory

import appSettings from "../config/settings";
import { types as sdkTypes, transit } from "./sdkLoader";
import Decimal from "decimal.js";
import axios from "axios";

export const apiBaseUrl = () => {
  const port = process.env.REACT_APP_DEV_API_SERVER_PORT;
  const useDevApiServer = process.env.NODE_ENV === "development" && !!port;
  const serverUrl = process.env.REACT_APP_MARKETPLACE_ROOT_URL;

  // In development, the dev API server is running in a different port
  if (useDevApiServer) {
    return `http://localhost:${port}`;
  }

  if (typeof window === 'undefined') {
    return serverUrl;
  }

  // Otherwise, use the same domain and port as the frontend
  return `${window.location.origin}`;
};

// Application type handlers for JS SDK.
//
// NOTE: keep in sync with `typeHandlers` in `server/api-util/sdk.js`
export const typeHandlers = [
  // Use Decimal type instead of SDK's BigDecimal.
  {
    type: sdkTypes.BigDecimal,
    customType: Decimal,
    writer: v => new sdkTypes.BigDecimal(v.toString()),
    reader: v => new Decimal(v.value)
  }
];

const serialize = data => {
  return transit.write(data, { typeHandlers, verbose: appSettings.sdk.transitVerbose });
};

const deserialize = str => {
  return transit.read(str, { typeHandlers });
};

const methods = {
  POST: "POST",
  GET: "GET",
  PUT: "PUT",
  PATCH: "PATCH",
  DELETE: "DELETE"
};

// If server/api returns data from SDK, you should set Content-Type to 'application/transit+json'
const request = (path, options = {}) => {
  const url = `${apiBaseUrl()}${path}`;
  const { credentials, headers, body, ...rest } = options;

  // If headers are not set, we assume that the body should be serialized as transit format.
  const shouldSerializeBody =
    (!headers || headers["Content-Type"] === "application/transit+json") && body;
  const bodyMaybe = shouldSerializeBody ? { body: serialize(body) } : {};

  const fetchOptions = {
    credentials: credentials || "include",
    // Since server/api mostly talks to Marketplace API using SDK,
    // we default to 'application/transit+json' as content type (as SDK uses transit).
    headers: headers || { "Content-Type": "application/transit+json" },
    ...bodyMaybe,
    ...rest
  };

  if (typeof window === "undefined") {
    return null;
  }

  return window.fetch(url, fetchOptions).then(res => {
    const contentTypeHeader = res.headers.get("Content-Type");
    const contentType = contentTypeHeader ? contentTypeHeader.split(";")[0] : null;

    if (res.status >= 400) {
      return res.json().then(data => {
        let e = new Error();
        e = Object.assign(e, data);

        throw e;
      });
    }
    if (contentType === "application/transit+json") {
      return res.text().then(deserialize);
    } else if (contentType === "application/json") {
      return res.json();
    }
    return res.text();
  });
};

// Keep the previous parameter order for the post method.
// For now, only POST has own specific function, but you can create more or use request directly.
const post = (path, body, options = {}) => {
  const requestOptions = {
    ...options,
    method: methods.POST,
    body
  };

  return request(path, requestOptions);
};

const get = (path, options = {}) => {
  const requestOptions = {
    ...options,
    method: methods.GET
  };

  return request(path, requestOptions);
};

const put = (path, body, options = {}) => {
  const requestOptions = {
    ...options,
    method: methods.PUT,
    body
  };

  return request(path, requestOptions);
};

const baseAxiosInstance = axios.create({
  baseURL: apiBaseUrl(),
  withCredentials: true,
  headers: {
    Accept: 'application/transit+json',
    'Content-Type': 'application/transit+json',
  },
  transformResponse: data => {
    const { data: responseData } = deserialize(data);
    return responseData;
  },
  transformRequest: data => serialize(data),
});

const axiosRequest = (path, options = {}) => baseAxiosInstance({
  url: path,
  ...options,
});

const axiosGet = path => {
  return axiosRequest(path, { method: methods.GET });
};

// Fetch transaction line items from the local API endpoint.
//
// See `server/api/transaction-line-items.js` to see what data should
// be sent in the body.
export const transactionLineItems = body => {
  return post("/api/transaction-line-items", body);
};

// Initiate a privileged transaction.
//
// With privileged transitions, the transactions need to be created
// from the backend. This endpoint enables sending the order data to
// the local backend, and passing that to the Marketplace API.
//
// See `server/api/initiate-privileged.js` to see what data should be
// sent in the body.
export const initiatePrivileged = body => {
  return post("/api/transaction/initiate", body);
};

// Transition a transaction with a privileged transition.
//
// This is similar to the `initiatePrivileged` above. It will use the
// backend for the transition. The backend endpoint will add the
// payment line items to the transition params.
//
// See `server/api/transition-privileged.js` to see what data should
// be sent in the body.
export const transitionPrivileged = body => {
  return post("/api/transition-privileged", body);
};

// Create user with identity provider (e.g. Facebook or Google)
//
// If loginWithIdp api call fails and user can't authenticate to Marketplace API with idp
// we will show option to create a new user with idp.
// For that user needs to confirm data fetched from the idp.
// After the confirmation, this endpoint is called to create a new user with confirmed data.
//
// See `server/api/auth/createUserWithIdp.js` to see what data should
// be sent in the body.
export const createUserWithIdp = body => {
  return post("/api/auth/create-user-with-idp", body);
};

export const confirmTransaction = body => {
  return post("/api/transaction/confirm", body);
};

export const createUser = body => {
  return post("/api/current_user/create", body);
};

export const getDotsInfo = () => {
  return get("/api/dots");
};

export const getDotsVerificationLink = () => {
  return get("/api/dots/verification");
};

export const getDotsPayoutLink = () => {
  return get("/api/dots/withdraw");
};

export const getDotsManagePayoutLink = () => {
  return get("/api/dots/manage-payouts");
};

export const showUserApi = ({ username, ...additionalParams }) => {
  const queryParams = new URLSearchParams({ username, ...additionalParams }).toString();
  return axiosGet(`/api/user-profile?${queryParams}`);
};

export const updateUserProfileApi = body => {
  return post("/api/user-profile", body);
};

export const finalizeSignUp = (body = {}) => {
  return put("/api/user-profile/finalize-sign-up", body);
};
