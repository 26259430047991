/////////////////////////////////////////////////////////
// Configurations related to listing.                  //
// Main configuration here is the extended data config //
/////////////////////////////////////////////////////////

// NOTE: if you want to change the structure of the data,
// you should also check src/util/configHelpers.js
// some validation is added there.

/**
 * Configuration options for listing fields (custom extended data fields):
 * - key:                           Unique key for the extended data field.
 * - scope (optional):              Scope of the extended data can be either 'public' or 'private'.
 *                                  Default value: 'public'.
 *                                  Note: listing doesn't support 'protected' scope atm.
 * - includeForListingTypes:        An array of listing types, for which the extended
 *   (optional)                     data is relevant and should be added.
 * - schemaType (optional):         Schema for this extended data field.
 *                                  This is relevant when rendering components and querying listings.
 *                                  Possible values: 'enum', 'multi-enum', 'text', 'long', 'boolean'.
 * - enumOptions (optional):        Options shown for 'enum' and 'multi-enum' extended data.
 *                                  These are used to render options for inputs and filters on
 *                                  EditListingPage, ListingPage, and SearchPage.
 * - filterConfig:                  Filter configuration for listings query.
 *    - indexForSearch (optional):    If set as true, it is assumed that the extended data key has
 *                                    search index in place. I.e. the key can be used to filter
 *                                    listing queries (then scope needs to be 'public').
 *                                    Note: Flex CLI can be used to set search index for the key:
 *                                    https://www.sharetribe.com/docs/references/extended-data/#search-schema
 *                                    Read more about filtering listings with public data keys from API Reference:
 *                                    https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
 *                                    Default value: false,
 *   - filterType:                    Sometimes a single schemaType can be rendered with different filter components.
 *                                    For 'enum' schema, filterType can be 'SelectSingleFilter' or 'SelectMultipleFilter'
 *   - label:                         Label for the filter, if the field can be used as query filter
 *   - searchMode (optional):         Search mode for indexed data with multi-enum schema.
 *                                    Possible values: 'has_all' or 'has_any'.
 *   - group:                         SearchPageWithMap has grouped filters. Possible values: 'primary' or 'secondary'.
 * - showConfig:                    Configuration for rendering listing. (How the field should be shown.)
 *   - label:                         Label for the saved data.
 *   - isDetail                       Can be used to hide detail row (of type enum, boolean, or long) from listing page.
 *                                    Default value: true,
 * - saveConfig:                    Configuration for adding and modifying extended data fields.
 *   - label:                         Label for the input field.
 *   - placeholderMessage (optional): Default message for user input.
 *   - isRequired (optional):         Is the field required for providers to fill
 *   - requiredMessage (optional):    Message for those fields, which are mandatory.
 */
export const listingFields = [
  {
    label: 'Riding Gear',
    key: 'ridingGear',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      {
        label: 'Base & Mid Layers',
        option: 'base_mid_layers',
      },
      {
        label: 'Boots',
        option: 'boots',
      },
      {
        label: 'Eyewear',
        option: 'eyewear',
      },
      {
        label: 'Gloves',
        option: 'gloves',
      },
      {
        label: 'Heated Gear',
        option: 'heated_gear',
      },
      {
        label: 'Headwear',
        option: 'headwear',
      },
      {
        label: 'Jackets & Vests',
        option: 'jackets_vests',
      },
      {
        label: 'Jerseys',
        option: 'jerseys',
      },
      {
        label: 'Pants',
        option: 'pants',
      },
      {
        label: 'Protection',
        option: 'protection',
      },
      {
        label: 'Rain Gear',
        option: 'rain_gear',
      },
      {
        label: 'Riding Suits',
        option: 'riding_suits',
      },
      {
        label: 'Youth Gear',
        option: 'youth_gear',
      },
    ],
    filterConfig: {
      indexForSearch: true,
      label: 'Riding Gear',
      searchMode: 'has_all',
      group: 'primary',
    },
  },
  {
    label: 'Helmets',
    key: 'helmets',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      {
        label: 'Full Face',
        option: 'full_face',
      },
      {
        label: 'Modular',
        option: 'modular',
      },
      {
        label: 'Dual Sport',
        option: 'dual_sport',
      },
      {
        label: 'Dirt',
        option: 'dirt',
      },
      {
        label: 'Open Face and 3/4',
        option: 'open_face_34',
      },
      {
        label: '1/2 Helmet',
        option: 'half_helmet',
      },
      {
        label: 'Helmet Accessories',
        option: 'helmet_accessories',
      },
    ],
    filterConfig: {
      indexForSearch: true,
      label: 'Helmets',
      searchMode: 'has_all',
      group: 'primary',
    },
  },
  {
    label: 'Parts',
    key: 'parts',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      {
        label: 'Air & Fuel',
        option: 'air_fuel',
      },
      {
        label: 'Audio & Speakers',
        option: 'audio_speakers',
      },
      {
        label: 'Batteries & Electrical',
        option: 'batteries_electrical',
      },
      {
        label: 'Bike Protection',
        option: 'bike_protection',
      },
      {
        label: 'Body, Fairing & Fender',
        option: 'body_fairing_fender',
      },
      {
        label: 'Brakes',
        option: 'brakes',
      },
      {
        label: 'Dash & Gauges',
        option: 'dash_gauges',
      },
      {
        label: 'Drive & Transmission',
        option: 'drive_transmission',
      },
      {
        label: 'Exhaust',
        option: 'exhaust',
      },
      {
        label: 'Engine',
        option: 'engine',
      },
      {
        label: 'Filters',
        option: 'filters',
      },
      {
        label: 'Foot Controls',
        option: 'foot_controls',
      },
      {
        label: 'Gas & Oil Tanks',
        option: 'gas_oil_tanks',
      },
      {
        label: 'Handlebars & Controls',
        option: 'handlebars_controls',
      },
      {
        label: 'Lighting',
        option: 'lighting',
      },
      {
        label: 'Mirrors',
        option: 'mirrors',
      },
      {
        label: 'Seats & Sissy Bars',
        option: 'seats_sissy_bars',
      },
      {
        label: 'Suspension & Frame',
        option: 'suspension_frame',
      },
      {
        label: 'Wheel & Axle',
        option: 'wheel_axle',
      },
      {
        label: 'Windshields & Windscreens',
        option: 'windshields_windscreens',
      },
    ],
    filterConfig: {
      indexForSearch: true,
      filterType: 'SelectMultipleFilter',
      label: 'Parts',
      group: 'primary',
    },
  },
  {
    label: 'Accessories',
    key: 'accessories',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      {
        label: 'Battery Chargers',
        option: 'battery_chargers',
      },
      {
        label: 'Bluetooth Communications',
        option: 'bluetooth_communications',
      },
      {
        label: 'Books, Magazines & Other Media',
        option: 'books_magazines_media',
      },
      {
        label: 'Camping',
        option: 'camping',
      },
      {
        label: 'Cleaning Supplies',
        option: 'cleaning_supplies',
      },
      {
        label: 'Chain Care',
        option: 'chain_care',
      },
      {
        label: 'Electronics & Mounts',
        option: 'electronics_mounts',
      },
      {
        label: 'Food & Beverage',
        option: 'food_beverage',
      },
      {
        label: 'Gifts',
        option: 'gifts',
      },
      {
        label: 'Luggage & Backpacks',
        option: 'luggage_backpacks',
      },
      {
        label: 'Maps',
        option: 'maps',
      },
      {
        label: 'Oil & Fluids',
        option: 'oil_fluids',
      },
      {
        label: 'Security',
        option: 'security',
      },
      {
        label: 'Stickers & Decals',
        option: 'stickers_decals',
      },
      {
        label: 'Tools',
        option: 'tools',
      },
      {
        label: 'Transport & Storage',
        option: 'transport_storage',
      },
      {
        label: 'Other',
        option: 'other_accessories',
      },
    ],
    filterConfig: {
      indexForSearch: true,
      filterType: 'SelectMultipleFilter',
      label: 'Accessories',
      group: 'primary',
    },
  },
  {
    label: 'Tires',
    key: 'tires',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      {
        label: 'Dirtbike',
        option: 'dirtbike_tires',
      },
      {
        label: 'Dual Sport',
        option: 'dual_sport_tires',
      },
      {
        label: 'Race',
        option: 'race_tires',
      },
      {
        label: 'Super Moto',
        option: 'supermoto_tires',
      },
      {
        label: 'Sport',
        option: 'sport_tires',
      },
      {
        label: 'Touring',
        option: 'touring_tires',
      },
      {
        label: 'V-Twin & Cruiser',
        option: 'v_twin_cruiser_tires',
      },
      {
        label: 'Vintage',
        option: 'vintage_tires',
      },
      {
        label: 'Tire Accessories',
        option: 'tire_accessories',
      },
    ],
    filterConfig: {
      indexForSearch: true,
      filterType: 'SelectMultipleFilter',
      label: 'Tires',
      group: 'primary',
    },
  },
  {
    label: 'Casual Clothing',
    key: 'casualClothing',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      {
        label: 'Hats',
        option: 'hats_clothing',
      },
      {
        label: 'Jackets',
        option: 'jackets_clothing',
      },
      {
        label: 'Shirts',
        option: 'shirts_clothing',
      },
      {
        label: 'Other',
        option: 'other_casual_clothing',
      },
    ],
    filterConfig: {
      indexForSearch: true,
      filterType: 'SelectMultipleFilter',
      label: 'Casual Clothing',
      group: 'primary',
    },
  },
  {
    label: 'Vehicles',
    key: 'vehicles',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      {
        label: 'ADV',
        option: 'adv_vehicles',
      },
      {
        label: 'Cafe',
        option: 'cafe_vehicles',
      },
      {
        label: 'Dirt',
        option: 'dirt_vehicles',
      },
      {
        label: 'Sport',
        option: 'sport_vehicles',
      },
      {
        label: 'Touring',
        option: 'touring_vehicles',
      },
      {
        label: 'V-Twin',
        option: 'v_twin_vehicles',
      },
      {
        label: 'Other',
        option: 'other_vehicles',
      },
    ],
    filterConfig: {
      indexForSearch: true,
      filterType: 'SelectMultipleFilter',
      label: 'Vehicles',
      group: 'primary',
    },
  },
  {
    key: 'category',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: 'city-bikes', label: 'City bikes' },
      { option: 'electric-bikes', label: 'Electric bikes' },
      { option: 'mountain-bikes', label: 'Mountain bikes' },
      { option: 'childrens-bikes', label: "Children's bikes" },
    ],
    filterConfig: {
      indexForSearch: true,
      filterType: 'SelectMultipleFilter',
      label: 'Category',
      group: 'primary',
    },
    showConfig: {
      label: 'Category',
      isDetail: true,
    },
    saveConfig: {
      label: 'Category',
      placeholderMessage: 'Select an option…',
      isRequired: true,
      requiredMessage: 'You need to select a category.',
    },
  },
  {
    key: 'tire',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: '29', label: '29' },
      { option: '28', label: '28' },
      { option: '27', label: '27' },
      { option: '26', label: '26' },
      { option: '24', label: '24' },
      { option: '20', label: '20' },
      { option: '18', label: '18' },
    ],
    filterConfig: {
      indexForSearch: true,
      label: 'Tire size',
      group: 'secondary',
    },
    showConfig: {
      label: 'Tire size',
      isDetail: true,
    },
    saveConfig: {
      label: 'Tire size',
      placeholderMessage: 'Select an option…',
      isRequired: true,
      requiredMessage: 'You need to select a tire size.',
    },
  },
  {
    key: 'brand',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: 'cube', label: 'Cube' },
      { option: 'diamant', label: 'Diamant' },
      { option: 'ghost', label: 'GHOST' },
      { option: 'giant', label: 'Giant' },
      { option: 'kalkhoff', label: 'Kalkhoff' },
      { option: 'kona', label: 'Kona' },
      { option: 'otler', label: 'Otler' },
      { option: 'vermont', label: 'Vermont' },
    ],
    filterConfig: {
      indexForSearch: true,
      label: 'Brand',
      group: 'primary',
    },
    showConfig: {
      label: 'Brand',
      isDetail: true,
    },
    saveConfig: {
      label: 'Brand',
      placeholderMessage: 'Select an option…',
      isRequired: true,
      requiredMessage: 'You need to select a brand.',
    },
  },
  {
    key: 'accessories',
    scope: 'public',
    schemaType: 'multi-enum',
    enumOptions: [
      { option: 'bell', label: 'Bell' },
      { option: 'lights', label: 'Lights' },
      { option: 'lock', label: 'Lock' },
      { option: 'mudguard', label: 'Mudguard' },
    ],
    filterConfig: {
      indexForSearch: true,
      label: 'Accessories',
      searchMode: 'has_all',
      group: 'secondary',
    },
    showConfig: {
      label: 'Accessories',
    },
    saveConfig: {
      label: 'Accessories',
      placeholderMessage: 'Select an option…',
      isRequired: false,
    },
  },

  // // An example of how to use transaction type specific custom fields and private data.
  // {
  //   key: 'note',
  //   scope: 'public',
  //   includeForListingTypes: ['product-selling'],
  //   schemaType: 'text',
  //   showConfig: {
  //     label: 'Extra notes',
  //   },
  //   saveConfig: {
  //     label: 'Extra notes',
  //     placeholderMessage: 'Some public extra note about this bike...',
  //   },
  // },
  // {
  //   key: 'privatenote',
  //   scope: 'private',
  //   includeForListingTypes: ['daily-booking'],
  //   schemaType: 'text',
  //   saveConfig: {
  //     label: 'Private notes',
  //     placeholderMessage: 'Some private note about this bike...',
  //   },
  // },
];

///////////////////////////////////////////////////////////////////////
// Configurations related to listing types and transaction processes //
///////////////////////////////////////////////////////////////////////

// A presets of supported listing configurations
//
// Note 1: With first iteration of hosted configs, we are unlikely to support
//         multiple listing types, even though this template has some
//         rudimentary support for it.
// Note 2: transaction type is part of listing type. It defines what transaction process and units
//         are used when transaction is created against a specific listing.

/**
 * Configuration options for listing experience:
 * - listingType:         Unique string. This will be saved to listing's public data on
 *                        EditListingWizard.
 * - label                Label for the listing type. Used as microcopy for options to select
 *                        listing type in EditListingWizard.
 * - transactionType      Set of configurations how this listing type will behave when transaction is
 *                        created.
 *   - process              Transaction process.
 *                          The process must match one of the processes that this client app can handle
 *                          (check src/util/transactions/transaction.js) and the process must also exists in correct
 *                          marketplace environment.
 *   - alias                Valid alias for the aforementioned process. This will be saved to listing's
 *                          public data as transctionProcessAlias and transaction is initiated with this.
 *   - unitType             Unit type is mainly used as pricing unit. This will be saved to
 *                          transaction's protected data.
 *                          Recommendation: don't use same unit types in completely different processes
 *                          ('item' sold should not be priced the same as 'item' booked).
 * - stockType            This is relevant only to listings using default-purchase process.
 *                        If set to 'oneItem', stock management is not showed and the listing is
 *                        considered unique (stock = 1).
 *                        Possible values: 'oneItem' and 'multipleItems'.
 *                        Default: 'multipleItems'.
 * - defaultListingFields This is relevant only to listings using default-inquiry process atm.
 *                        It contains price: true/false value to indicate, whether price should be shown.
 *                        If defaultListingFields.price is not explicitly set to _false_, price will be shown.
 */

export const ADMIN_ONLY_LISTING_TYPES = ['SellingWithoutOnlinePayment'];

export const listingTypes = [
  {
    listingType: 'daily-booking',
    label: 'Daily booking',
    transactionType: {
      process: 'default-booking',
      alias: 'default-booking/release-1',
      unitType: 'day',
    },
  },
  // // Here are some examples for other listingTypes
  // // TODO: SearchPage does not work well if both booking and product selling are used at the same time
  // {
  //   listingType: 'nightly-booking',
  //   label: 'Nightly booking',
  //   transactionType: {
  //     process: 'default-booking',
  //     alias: 'default-booking/release-1',
  //     unitType: 'night',
  //   },
  // },
  // {
  //   listingType: 'hourly-booking',
  //   label: 'Hourly booking',
  //   transactionType: {
  //     process: 'default-booking',
  //     alias: 'default-booking/release-1',
  //     unitType: 'hour',
  //   },
  // },
  // {
  //   listingType: 'product-selling',
  //   label: 'Sell bicycles',
  //   transactionType: {
  //     process: 'default-purchase',
  //     alias: 'default-purchase/release-1',
  //     unitType: 'item',
  //   },
  //   stockType: 'multipleItems',
  // },
  // {
  //   listingType: 'inquiry',
  //   label: 'Inquiry',
  //   transactionType: {
  //     process: 'default-inquiry',
  //     alias: 'default-inquiry/release-1',
  //     unitType: 'inquiry',
  //   },
  //   defaultListingFields: {
  //     price: false,
  //   },
  // },
];

// SearchPage can enforce listing query to only those listings with valid listingType
// However, it only works if you have set 'enum' type search schema for the public data fields
//   - listingType
//
//  Similar setup could be expanded to 2 other extended data fields:
//   - transactionProcessAlias
//   - unitType
//
// Read More:
// https://www.sharetribe.com/docs/how-to/manage-search-schemas-with-flex-cli/#adding-listing-search-schemas
export const enforceValidListingType = false;

export const listingCategories = [
  {
    label: 'Riding Gear',
    key: 'riding_gear',
    subcategories: [
      {
        label: 'Base & Mid Layers',
        key: 'base_mid_layers',
      },
      {
        label: 'Boots',
        key: 'boots',
      },
      {
        label: 'Eyewear',
        key: 'eyewear',
      },
      {
        label: 'Gloves',
        key: 'gloves',
      },
      {
        label: 'Heated Gear',
        key: 'heated_gear',
      },
      {
        label: 'Headwear',
        key: 'headwear',
      },
      {
        label: 'Jackets & Vests',
        key: 'jackets_vests',
      },
      {
        label: 'Jerseys',
        key: 'jerseys',
      },
      {
        label: 'Pants',
        key: 'pants',
      },
      {
        label: 'Protection',
        key: 'protection',
      },
      {
        label: 'Rain Gear',
        key: 'rain_gear',
      },
      {
        label: 'Riding Suits',
        key: 'riding_suits',
      },
      {
        label: 'Youth Gear',
        key: 'youth_gear',
      },
    ],
  },
  {
    label: 'Helmets',
    key: 'helmets',
    subcategories: [
      {
        label: 'Full Face',
        key: 'full_face',
      },
      {
        label: 'Modular',
        key: 'modular',
      },
      {
        label: 'Dual Sport',
        key: 'dual_sport',
      },
      {
        label: 'Dirt',
        key: 'dirt',
      },
      {
        label: 'Open Face and 3/4',
        key: 'open_face_34',
      },
      {
        label: '1/2 Helmet',
        key: 'half_helmet',
      },
      {
        label: 'Helmet Accessories',
        key: 'helmet_accessories',
      },
    ],
  },
  {
    label: 'Parts',
    key: 'parts',
    subcategories: [
      {
        label: 'Air & Fuel',
        key: 'air_fuel',
      },
      {
        label: 'Audio & Speakers',
        key: 'audio_speakers',
      },
      {
        label: 'Batteries & Electrical',
        key: 'batteries_electrical',
      },
      {
        label: 'Bike Protection',
        key: 'bike_protection',
      },
      {
        label: 'Body, Fairing & Fender',
        key: 'body_fairing_fender',
      },
      {
        label: 'Brakes',
        key: 'brakes',
      },
      {
        label: 'Dash & Gauges',
        key: 'dash_gauges',
      },
      {
        label: 'Drive & Transmission',
        key: 'drive_transmission',
      },
      {
        label: 'Exhaust',
        key: 'exhaust',
      },
      {
        label: 'Engine',
        key: 'engine',
      },
      {
        label: 'Filters',
        key: 'filters',
      },
      {
        label: 'Foot Controls',
        key: 'foot_controls',
      },
      {
        label: 'Gas & Oil Tanks',
        key: 'gas_oil_tanks',
      },
      {
        label: 'Handlebars & Controls',
        key: 'handlebars_controls',
      },
      {
        label: 'Lighting',
        key: 'lighting',
      },
      {
        label: 'Mirrors',
        key: 'mirrors',
      },
      {
        label: 'Seats & Sissy Bars',
        key: 'seats_sissy_bars',
      },
      {
        label: 'Suspension & Frame',
        key: 'suspension_frame',
      },
      {
        label: 'Wheel & Axle',
        key: 'wheel_axle',
      },
      {
        label: 'Windshields & Windscreens',
        key: 'windshields_windscreens',
      },
    ],
  },
  {
    label: 'Accessories',
    key: 'accessories',
    subcategories: [
      {
        label: 'Battery Chargers',
        key: 'battery_chargers',
      },
      {
        label: 'Bluetooth Communications',
        key: 'bluetooth_communications',
      },
      {
        label: 'Books, Magazines & Other Media',
        key: 'books_magazines_media',
      },
      {
        label: 'Camping',
        key: 'camping',
      },
      {
        label: 'Cleaning Supplies',
        key: 'cleaning_supplies',
      },
      {
        label: 'Chain Care',
        key: 'chain_care',
      },
      {
        label: 'Electronics & Mounts',
        key: 'electronics_mounts',
      },
      {
        label: 'Food & Beverage',
        key: 'food_beverage',
      },
      {
        label: 'Gifts',
        key: 'gifts',
      },
      {
        label: 'Luggage & Backpacks',
        key: 'luggage_backpacks',
      },
      {
        label: 'Maps',
        key: 'maps',
      },
      {
        label: 'Oil & Fluids',
        key: 'oil_fluids',
      },
      {
        label: 'Security',
        key: 'security',
      },
      {
        label: 'Stickers & Decals',
        key: 'stickers_decals',
      },
      {
        label: 'Tools',
        key: 'tools',
      },
      {
        label: 'Transport & Storage',
        key: 'transport_storage',
      },
      {
        label: 'Other',
        key: 'other_accessories',
      },
    ],
  },
  {
    label: 'Tires',
    key: 'tires',
    subcategories: [
      {
        label: 'Dirtbike',
        key: 'dirtbike_tires',
      },
      {
        label: 'Dual Sport',
        key: 'dual_sport_tires',
      },
      {
        label: 'Race',
        key: 'race_tires',
      },
      {
        label: 'Super Moto',
        key: 'supermoto_tires',
      },
      {
        label: 'Sport',
        key: 'sport_tires',
      },
      {
        label: 'Touring',
        key: 'touring_tires',
      },
      {
        label: 'V-Twin & Cruiser',
        key: 'v_twin_cruiser_tires',
      },
      {
        label: 'Vintage',
        key: 'vintage_tires',
      },
      {
        label: 'Tire Accessories',
        key: 'tire_accessories',
      },
    ],
  },
  {
    label: 'Casual Clothing',
    key: 'casual_clothing',
    subcategories: [
      {
        label: 'Hats',
        key: 'hats_clothing',
      },
      {
        label: 'Jackets',
        key: 'jackets_clothing',
      },
      {
        label: 'Shirts',
        key: 'shirts_clothing',
      },
      {
        label: 'Other',
        key: 'other_casual_clothing',
      },
    ],
  },
  {
    label: 'Vehicles',
    key: 'vehicles',
    subcategories: [
      {
        label: 'ADV',
        key: 'adv_vehicles',
      },
      {
        label: 'Cafe',
        key: 'cafe_vehicles',
      },
      {
        label: 'Dirt',
        key: 'dirt_vehicles',
      },
      {
        label: 'Sport',
        key: 'sport_vehicles',
      },
      {
        label: 'Touring',
        key: 'touring_vehicles',
      },
      {
        label: 'V-Twin',
        key: 'v_twin_vehicles',
      },
      {
        label: 'Other',
        key: 'other_vehicles',
      },
    ],
  },
];

const genders = [
  {
    label: 'Male',
    key: 'male',
  },
  {
    label: 'Female',
    key: 'female',
  },
  {
    label: 'Unisex',
    key: 'unisex',
  },
];

const itemConditions = [
  {
    label: 'New',
    key: 'new',
  },
  {
    label: 'Like New',
    key: 'like_new',
  },
  {
    label: 'Excellent',
    key: 'excellent',
  },
  {
    label: 'Very Good',
    key: 'very_good',
  },
  {
    label: 'Good',
    key: 'good',
  },
  {
    label: 'Fair',
    key: 'fair',
  },
  {
    label: 'Poor',
    key: 'poor',
  },
  {
    label: 'Not Functioning',
    key: 'not_functioning',
  },
];

const sizes = [
  {
    label: 'One Size Fits Most',
    key: 'one_size',
  },
  {
    label: 'XXS',
    key: 'xxs',
  },
  {
    label: 'XS',
    key: 'xs',
  },
  {
    label: 'S',
    key: 's',
  },
  {
    label: 'M',
    key: 'm',
  },
  {
    label: 'L',
    key: 'l',
  },
  {
    label: 'XL',
    key: 'xl',
  },
  {
    label: 'XXL',
    key: 'xxl',
  },
  {
    label: 'XXXL',
    key: 'xxxl',
  },
  {
    label: '28',
    key: '28',
  },
  {
    label: '30',
    key: '30',
  },
  {
    label: '32',
    key: '32',
  },
  {
    label: '34',
    key: '34',
  },
  {
    label: '36',
    key: '36',
  },
  {
    label: '38',
    key: '38',
  },
  {
    label: '40',
    key: '40',
  },
  {
    label: '42',
    key: '42',
  },
  {
    label: '44',
    key: '44',
  },
];

const shoesSizes = [
  {
    key: '4',
    label: '4',
  },
  {
    key: '4_5',
    label: '4.5',
  },
  {
    key: '5',
    label: '5',
  },
  {
    key: '5_5',
    label: '5.5',
  },
  {
    key: '6',
    label: '6',
  },
  {
    key: '6_5',
    label: '6.5',
  },
  {
    key: '7',
    label: '7',
  },
  {
    key: '7_5',
    label: '7.5',
  },
  {
    key: '8',
    label: '8',
  },
  {
    key: '8_5',
    label: '8.5',
  },
  {
    key: '9',
    label: '9',
  },
  {
    key: '9_5',
    label: '9.5',
  },
  {
    key: '10',
    label: '10',
  },
  {
    key: '10_5',
    label: '10.5',
  },
  {
    key: '11',
    label: '11',
  },
  {
    key: '11_5',
    label: '11.5',
  },
  {
    key: '12',
    label: '12',
  },
  {
    key: '12_5',
    label: '12.5',
  },
  {
    key: '13',
    label: '13',
  },
  {
    key: '13_5',
    label: '13.5',
  },
  {
    key: '14',
    label: '14',
  },
];

const vehicleConditions = [
  {
    label: 'New',
    key: 'new',
  },
  {
    label: 'Used',
    key: 'used',
  },
  {
    label: 'Does Not Run',
    key: 'does_not_run',
  },
];
const brands = [
  {
    label: 'Not sure',
    key: 'not_sure',
  },
  {
    label: '100%',
    key: '100',
  },
  {
    label: '509',
    key: '509',
  },
  {
    label: '6D Helmets',
    key: '6_d_helmets',
  },
  {
    label: '905 Racing',
    key: '905_racing',
  },
  {
    label: 'AAC',
    key: 'aac',
  },
  {
    label: 'Abus Locks',
    key: 'abus_locks',
  },
  {
    label: 'Accel',
    key: 'accel',
  },
  {
    label: 'Accutronix',
    key: 'accutronix',
  },
  {
    label: 'Acerbis',
    key: 'acerbis',
  },
  {
    label: 'AdMore Lighting',
    key: 'ad_more_lighting',
  },
  {
    label: 'Aether',
    key: 'aether',
  },
  {
    label: 'AFX Helmets',
    key: 'afx_helmets',
  },
  {
    label: 'Agency Power',
    key: 'agency_power',
  },
  {
    label: 'AGV Helmets',
    key: 'agv_helmets',
  },
  {
    label: 'Airaid',
    key: 'airaid',
  },
  {
    label: 'Airhawk',
    key: 'airhawk',
  },
  {
    label: 'Akrapovic Exhaust',
    key: 'akrapovic_exhaust',
  },
  {
    label: 'All Balls Racing',
    key: 'all_balls_racing',
  },
  {
    label: 'Alloy Art',
    key: 'alloy_art',
  },
  {
    label: 'ALL RITE',
    key: 'all_rite',
  },
  {
    label: 'All Terrain Concepts',
    key: 'all_terrain_concepts',
  },
  {
    label: 'Alpinestars',
    key: 'alpinestars',
  },
  {
    label: 'Alps Mountaineering',
    key: 'alps_mountaineering',
  },
  {
    label: 'AltRider',
    key: 'alt_rider',
  },
  {
    label: 'AMK',
    key: 'amk',
  },
  {
    label: 'Answer Racing',
    key: 'answer_racing',
  },
  {
    label: 'Antigravity Batteries',
    key: 'antigravity_batteries',
  },
  {
    label: 'AO Coolers',
    key: 'ao_coolers',
  },
  {
    label: 'Aquatic AV',
    key: 'aquatic_av',
  },
  {
    label: 'Arai Helmets',
    key: 'arai_helmets',
  },
  {
    label: 'ARB',
    key: 'arb',
  },
  {
    label: 'Arc Levers',
    key: 'arc_levers',
  },
  {
    label: 'Arctiva',
    key: 'arctiva',
  },
  {
    label: 'Arlen Ness',
    key: 'arlen_ness',
  },
  {
    label: 'Armour Bodies',
    key: 'armour_bodies',
  },
  {
    label: 'Arrow Exhaust',
    key: 'arrow_exhaust',
  },
  {
    label: 'Asterisk',
    key: 'asterisk',
  },
  {
    label: 'ASV Inventions',
    key: 'asv_inventions',
  },
  {
    label: 'Athena',
    key: 'athena',
  },
  {
    label: 'Atlas Neck Brace',
    key: 'atlas_neck_brace',
  },
  {
    label: 'ATV TEK',
    key: 'atv_tek',
  },
  {
    label: 'Avon Grips',
    key: 'avon_grips',
  },
  {
    label: 'Avon Tires',
    key: 'avon_tires',
  },
  {
    label: 'Axia Alloys',
    key: 'axia_alloys',
  },
  {
    label: 'AXIAL',
    key: 'axial',
  },
  {
    label: 'Azonic',
    key: 'azonic',
  },
  {
    label: 'Badlands',
    key: 'badlands',
  },
  {
    label: 'Baffin',
    key: 'baffin',
  },
  {
    label: 'Bahn',
    key: 'bahn',
  },
  {
    label: 'Baja Designs',
    key: 'baja_designs',
  },
  {
    label: 'Barbour',
    key: 'barbour',
  },
  {
    label: 'Bare Knuckle Performance',
    key: 'bare_knuckle_performance',
  },
  {
    label: 'Barkbusters',
    key: 'barkbusters',
  },
  {
    label: 'Barnett',
    key: 'barnett',
  },
  {
    label: 'Baron',
    key: 'baron',
  },
  {
    label: 'Bassani Exhaust',
    key: 'bassani_exhaust',
  },
  {
    label: 'Battery Tender',
    key: 'battery_tender',
  },
  {
    label: 'Battistinis',
    key: 'battistinis',
  },
  {
    label: 'Bazzaz',
    key: 'bazzaz',
  },
  {
    label: 'BBR Motorsports',
    key: 'bbr_motorsports',
  },
  {
    label: 'Bell Helmets',
    key: 'bell_helmets',
  },
  {
    label: 'Bel Ray Lubricants',
    key: 'bel_ray_lubricants',
  },
  {
    label: 'Belstaff',
    key: 'belstaff',
  },
  {
    label: 'Belt Drives Limited',
    key: 'belt_drives_limited',
  },
  {
    label: "Ben's",
    key: 'bens',
  },
  {
    label: 'Bering',
    key: 'bering',
  },
  {
    label: 'Big Gun',
    key: 'big_gun',
  },
  {
    label: 'Bikeman Performance',
    key: 'bikeman_performance',
  },
  {
    label: 'BikeMaster',
    key: 'bike_master',
  },
  {
    label: "Biker's Choice",
    key: 'bikers_choice',
  },
  {
    label: "Bill's Pipes",
    key: 'bills_pipes',
  },
  {
    label: 'Bilt',
    key: 'bilt',
  },
  {
    label: 'Biltwell Apparel',
    key: 'biltwell_apparel',
  },
  {
    label: 'Biltwell Parts',
    key: 'biltwell_parts',
  },
  {
    label: 'Black Brand',
    key: 'black_brand',
  },
  {
    label: 'Black Rhino Tires',
    key: 'black_rhino_tires',
  },
  {
    label: 'BMC Air Filters',
    key: 'bmc_air_filters',
  },
  {
    label: 'BMW',
    key: 'bmw',
  },
  {
    label: 'Boblbee',
    key: 'boblbee',
  },
  {
    label: 'Bobster Eyewear',
    key: 'bobster_eyewear',
  },
  {
    label: 'Bold Performance',
    key: 'bold_performance',
  },
  {
    label: 'Bolt Hardware',
    key: 'bolt_hardware',
  },
  {
    label: 'Boss Audio Systems',
    key: 'boss_audio_systems',
  },
  {
    label: 'Boyesen',
    key: 'boyesen',
  },
  {
    label: 'Braking Brakes',
    key: 'braking_brakes',
  },
  {
    label: 'Brass Balls Cycles',
    key: 'brass_balls_cycles',
  },
  {
    label: 'Brembo',
    key: 'brembo',
  },
  {
    label: 'Bridgestone Tires',
    key: 'bridgestone_tires',
  },
  {
    label: 'Brite-Lites',
    key: 'brite_lites',
  },
  {
    label: 'British Customs',
    key: 'british_customs',
  },
  {
    label: 'Bull-it Jeans',
    key: 'bull_it_jeans',
  },
  {
    label: 'Bully Locks',
    key: 'bully_locks',
  },
  {
    label: 'Burly Brand',
    key: 'burly_brand',
  },
  {
    label: 'Bursig',
    key: 'bursig',
  },
  {
    label: 'Butler Maps',
    key: 'butler_maps',
  },
  {
    label: 'Cane Creek Cycling',
    key: 'cane_creek_cycling',
  },
  {
    label: 'Cardo Systems',
    key: 'cardo_systems',
  },
  {
    label: 'Carl Brouhard Designs',
    key: 'carl_brouhard_designs',
  },
  {
    label: 'Castrol',
    key: 'castrol',
  },
  {
    label: 'Chicken Hawk Racing',
    key: 'chicken_hawk_racing',
  },
  {
    label: 'Ciro',
    key: 'ciro',
  },
  {
    label: 'CLICKnRIDE',
    key: 'clic_kn_ride',
  },
  {
    label: 'Clymer Publications',
    key: 'clymer_publications',
  },
  {
    label: 'Cobra Exhaust',
    key: 'cobra_exhaust',
  },
  {
    label: 'Combat Wipes',
    key: 'combat_wipes',
  },
  {
    label: 'Competition Werkes',
    key: 'competition_werkes',
  },
  {
    label: 'Condor',
    key: 'condor',
  },
  {
    label: 'Continental Tires',
    key: 'continental_tires',
  },
  {
    label: 'Cortech',
    key: 'cortech',
  },
  {
    label: 'CoverMax',
    key: 'cover_max',
  },
  {
    label: 'Covingtons',
    key: 'covingtons',
  },
  {
    label: 'Cox Racing',
    key: 'cox_racing',
  },
  {
    label: 'Crankbrothers',
    key: 'crankbrothers',
  },
  {
    label: 'CRG',
    key: 'crg',
  },
  {
    label: 'Crusher',
    key: 'crusher',
  },
  {
    label: 'CruzTOOLS',
    key: 'cruz_tools',
  },
  {
    label: 'CST',
    key: 'cst',
  },
  {
    label: 'CushCore',
    key: 'cush_core',
  },
  {
    label: 'CustomAcces',
    key: 'custom_acces',
  },
  {
    label: 'Custom Cycle Engineering',
    key: 'custom_cycle_engineering',
  },
  {
    label: 'Custom Dynamics',
    key: 'custom_dynamics',
  },
  {
    label: 'CV4',
    key: 'cv_4',
  },
  {
    label: 'Cycle Care',
    key: 'cycle_care',
  },
  {
    label: 'Cyclesmiths',
    key: 'cyclesmiths',
  },
  {
    label: 'Cycle Visions',
    key: 'cycle_visions',
  },
  {
    label: 'Cycra',
    key: 'cycra',
  },
  {
    label: 'Dainese',
    key: 'dainese',
  },
  {
    label: 'Dakota Digital',
    key: 'dakota_digital',
  },
  {
    label: 'Danmoto',
    key: 'danmoto',
  },
  {
    label: 'Danny Gray',
    key: 'danny_gray',
  },
  {
    label: 'Dart Flyscreens',
    key: 'dart_flyscreens',
  },
  {
    label: 'Daytona Boots',
    key: 'daytona_boots',
  },
  {
    label: 'DCOR',
    key: 'dcor',
  },
  {
    label: 'DeatschWerks',
    key: 'deatsch_werks',
  },
  {
    label: 'Demon Powersports',
    key: 'demon_powersports',
  },
  {
    label: 'Denali',
    key: 'denali',
  },
  {
    label: 'Design Engineering, Inc.',
    key: 'design_engineering_inc',
  },
  {
    label: 'Devol',
    key: 'devol',
  },
  {
    label: 'Dickies',
    key: 'dickies',
  },
  {
    label: 'DID Chain',
    key: 'did_chain',
  },
  {
    label: 'Digital Guard Dawg',
    key: 'digital_guard_dawg',
  },
  {
    label: 'DMP',
    key: 'dmp',
  },
  {
    label: 'Domino Grips',
    key: 'domino_grips',
  },
  {
    label: 'Double Tough',
    key: 'double_tough',
  },
  {
    label: 'Dowco',
    key: 'dowco',
  },
  {
    label: 'DP Brakes',
    key: 'dp_brakes',
  },
  {
    label: 'DragonFire Racing',
    key: 'dragon_fire_racing',
  },
  {
    label: 'Dragon Sunglasses',
    key: 'dragon_sunglasses',
  },
  {
    label: 'Drag Specialties',
    key: 'drag_specialties',
  },
  {
    label: 'Drayko Jeans',
    key: 'drayko_jeans',
  },
  {
    label: 'Drift Innovation',
    key: 'drift_innovation',
  },
  {
    label: 'Driven Racing',
    key: 'driven_racing',
  },
  {
    label: 'DRYGUY',
    key: 'dryguy',
  },
  {
    label: 'DrySpec',
    key: 'dry_spec',
  },
  {
    label: 'Dubach Racing',
    key: 'dubach_racing',
  },
  {
    label: 'Dunlop Tires',
    key: 'dunlop_tires',
  },
  {
    label: 'Duraboost',
    key: 'duraboost',
  },
  {
    label: 'Duro Tires',
    key: 'duro_tires',
  },
  {
    label: 'DV8 Offroad',
    key: 'dv_8_offroad',
  },
  {
    label: 'DWO',
    key: 'dwo',
  },
  {
    label: 'DYNAPLUG',
    key: 'dynaplug',
  },
  {
    label: 'Dynojet',
    key: 'dynojet',
  },
  {
    label: 'E4S Windscreens',
    key: 'e_4_s_windscreens',
  },
  {
    label: 'Eagle Grit',
    key: 'eagle_grit',
  },
  {
    label: 'Eastern Motorcycle Parts',
    key: 'eastern_motorcycle_parts',
  },
  {
    label: 'Eazi-Grip',
    key: 'eazi_grip',
  },
  {
    label: 'EBC Brakes',
    key: 'ebc_brakes',
  },
  {
    label: 'Echo',
    key: 'echo',
  },
  {
    label: 'ECOXGEAR',
    key: 'ecoxgear',
  },
  {
    label: 'Eddie Trotta Designs',
    key: 'eddie_trotta_designs',
  },
  {
    label: 'EFX Tires',
    key: 'efx_tires',
  },
  {
    label: 'Eibach',
    key: 'eibach',
  },
  {
    label: 'EK Chain',
    key: 'ek_chain',
  },
  {
    label: 'EKS Goggles',
    key: 'eks_goggles',
  },
  {
    label: 'Element Fire',
    key: 'element_fire',
  },
  {
    label: 'E-Line Accessories',
    key: 'e_line_accessories',
  },
  {
    label: 'EMD',
    key: 'emd',
  },
  {
    label: 'Emgo',
    key: 'emgo',
  },
  {
    label: 'Enduristan',
    key: 'enduristan',
  },
  {
    label: 'Enduro Engineering',
    key: 'enduro_engineering',
  },
  {
    label: 'Energy Suspension',
    key: 'energy_suspension',
  },
  {
    label: 'Engine Ice',
    key: 'engine_ice',
  },
  {
    label: 'EPI Performance',
    key: 'epi_performance',
  },
  {
    label: 'ethirteen',
    key: 'ethirteen',
  },
  {
    label: 'Evans Cooling',
    key: 'evans_cooling',
  },
  {
    label: 'Evel',
    key: 'evel',
  },
  {
    label: 'Evotech Performance',
    key: 'evotech_performance',
  },
  {
    label: 'EVS',
    key: 'evs',
  },
  {
    label: 'Excel',
    key: 'excel',
  },
  {
    label: 'Factor 55',
    key: 'factor_55',
  },
  {
    label: 'Factory Connection',
    key: 'factory_connection',
  },
  {
    label: 'Factory Effex',
    key: 'factory_effex',
  },
  {
    label: 'Factory Links',
    key: 'factory_links',
  },
  {
    label: 'Fantic',
    key: 'fantic',
  },
  {
    label: 'Fasthouse',
    key: 'fasthouse',
  },
  {
    label: 'Fat Baggers Inc.',
    key: 'fat_baggers_inc',
  },
  {
    label: 'Feedback Sports',
    key: 'feedback_sports',
  },
  {
    label: 'Fenda Extenda',
    key: 'fenda_extenda',
  },
  {
    label: 'Ferodo',
    key: 'ferodo',
  },
  {
    label: 'Feuling',
    key: 'feuling',
  },
  {
    label: 'Fi2000',
    key: 'fi_2000',
  },
  {
    label: 'Fieldsheer',
    key: 'fieldsheer',
  },
  {
    label: 'Figurati Designs',
    key: 'figurati_designs',
  },
  {
    label: 'FIMCO Industries',
    key: 'fimco_industries',
  },
  {
    label: 'Firebrand Exhaust',
    key: 'firebrand_exhaust',
  },
  {
    label: 'Fire Power',
    key: 'fire_power',
  },
  {
    label: 'First Alert',
    key: 'first_alert',
  },
  {
    label: 'Firstgear',
    key: 'firstgear',
  },
  {
    label: 'First Manufacturing',
    key: 'first_manufacturing',
  },
  {
    label: 'Fist Handwear',
    key: 'fist_handwear',
  },
  {
    label: 'Fizik',
    key: 'fizik',
  },
  {
    label: 'Flanders',
    key: 'flanders',
  },
  {
    label: 'Flash Tune',
    key: 'flash_tune',
  },
  {
    label: 'Flat Out',
    key: 'flat_out',
  },
  {
    label: 'Flat Tire Defender',
    key: 'flat_tire_defender',
  },
  {
    label: 'Flo Motorsports',
    key: 'flo_motorsports',
  },
  {
    label: 'FLU Designs',
    key: 'flu_designs',
  },
  {
    label: 'Fly Racing Dirt',
    key: 'fly_racing_dirt',
  },
  {
    label: 'Fly Racing MTB',
    key: 'fly_racing_mtb',
  },
  {
    label: 'Fly Racing Snow',
    key: 'fly_racing_snow',
  },
  {
    label: 'Fly Racing Street',
    key: 'fly_racing_street',
  },
  {
    label: 'FMF Exhaust',
    key: 'fmf_exhaust',
  },
  {
    label: 'Forcefield',
    key: 'forcefield',
  },
  {
    label: 'Forma Boots',
    key: 'forma_boots',
  },
  {
    label: 'Fox Factory',
    key: 'fox_factory',
  },
  {
    label: 'Fox Racing',
    key: 'fox_racing',
  },
  {
    label: 'FPS Racing',
    key: 'fps_racing',
  },
  {
    label: 'Fram',
    key: 'fram',
  },
  {
    label: 'Freedom Performance Exhaust',
    key: 'freedom_performance_exhaust',
  },
  {
    label: 'Frogg Toggs',
    key: 'frogg_toggs',
  },
  {
    label: 'Fuel UTV',
    key: 'fuel_utv',
  },
  {
    label: 'FXR',
    key: 'fxr',
  },
  {
    label: 'G2 Ergonomics',
    key: 'g_2_ergonomics',
  },
  {
    label: 'Gaerne',
    key: 'gaerne',
  },
  {
    label: 'Gale Speed',
    key: 'gale_speed',
  },
  {
    label: 'Galfer Brakes',
    key: 'galfer_brakes',
  },
  {
    label: 'Garage Boss',
    key: 'garage_boss',
  },
  {
    label: 'Garmin',
    key: 'garmin',
  },
  {
    label: 'Gasbox',
    key: 'gasbox',
  },
  {
    label: 'Gate King',
    key: 'gate_king',
  },
  {
    label: 'Gates',
    key: 'gates',
  },
  {
    label: 'Gator Waders',
    key: 'gator_waders',
  },
  {
    label: 'GBC Tire',
    key: 'gbc_tire',
  },
  {
    label: 'GB Racing',
    key: 'gb_racing',
  },
  {
    label: 'Genuine Innovations',
    key: 'genuine_innovations',
  },
  {
    label: 'GEN-Y Hitch',
    key: 'gen_y_hitch',
  },
  {
    label: 'Gerbing Heated Clothing',
    key: 'gerbing_heated_clothing',
  },
  {
    label: 'G-FORM',
    key: 'g_form',
  },
  {
    label: 'Giant Loop Luggage',
    key: 'giant_loop_luggage',
  },
  {
    label: 'Gibson Performance',
    key: 'gibson_performance',
  },
  {
    label: 'Givi',
    key: 'givi',
  },
  {
    label: 'GlobeScout',
    key: 'globe_scout',
  },
  {
    label: 'Glove Tacts',
    key: 'glove_tacts',
  },
  {
    label: 'Glue Tread',
    key: 'glue_tread',
  },
  {
    label: 'GMAX Helmets',
    key: 'gmax_helmets',
  },
  {
    label: 'GMZ Race Products',
    key: 'gmz_race_products',
  },
  {
    label: "Go Fast Don't Die",
    key: 'go_fast_dont_die',
  },
  {
    label: 'Golan Products',
    key: 'golan_products',
  },
  {
    label: 'Gold Eagle',
    key: 'gold_eagle',
  },
  {
    label: 'Goodyear',
    key: 'goodyear',
  },
  {
    label: 'Gorilla Automotive',
    key: 'gorilla_automotive',
  },
  {
    label: 'GPR Stabilizer',
    key: 'gpr_stabilizer',
  },
  {
    label: 'Grangers',
    key: 'grangers',
  },
  {
    label: 'Grant Products',
    key: 'grant_products',
  },
  {
    label: 'Graves Motorsports',
    key: 'graves_motorsports',
  },
  {
    label: 'Griots Garage',
    key: 'griots_garage',
  },
  {
    label: 'GSI Outdoors',
    key: 'gsi_outdoors',
  },
  {
    label: 'Halcyon Goggles',
    key: 'halcyon_goggles',
  },
  {
    label: 'Hammerhead',
    key: 'hammerhead',
  },
  {
    label: 'Hardbagger',
    key: 'hardbagger',
  },
  {
    label: 'Hardline',
    key: 'hardline',
  },
  {
    label: 'Hardstreet',
    key: 'hardstreet',
  },
  {
    label: 'Harley Davidson',
    key: 'harley_davidson',
  },
  {
    label: 'Hayden Enterprises',
    key: 'hayden_enterprises',
  },
  {
    label: 'Hearos',
    key: 'hearos',
  },
  {
    label: 'Heidenau Tires',
    key: 'heidenau_tires',
  },
  {
    label: 'Held',
    key: 'held',
  },
  {
    label: 'HeliBars',
    key: 'heli_bars',
  },
  {
    label: 'Helite',
    key: 'helite',
  },
  {
    label: 'HelmetLok',
    key: 'helmet_lok',
  },
  {
    label: 'Heretic Studios',
    key: 'heretic_studios',
  },
  {
    label: 'HiFloFiltro',
    key: 'hi_flo_filtro',
  },
  {
    label: 'High Lifter Products',
    key: 'high_lifter_products',
  },
  {
    label: 'High Roller',
    key: 'high_roller',
  },
  {
    label: 'Highsider',
    key: 'highsider',
  },
  {
    label: 'Hightail Hair',
    key: 'hightail_hair',
  },
  {
    label: 'Highway 21',
    key: 'highway_21',
  },
  {
    label: 'Hindle Exhaust',
    key: 'hindle_exhaust',
  },
  {
    label: 'Hinson Clutches',
    key: 'hinson_clutches',
  },
  {
    label: 'Hitchfire',
    key: 'hitchfire',
  },
  {
    label: 'HJC Helmets',
    key: 'hjc_helmets',
  },
  {
    label: 'HMF Engineering',
    key: 'hmf_engineering',
  },
  {
    label: 'HMK',
    key: 'hmk',
  },
  {
    label: 'Hofmann Designs',
    key: 'hofmann_designs',
  },
  {
    label: 'Hogtunes',
    key: 'hogtunes',
  },
  {
    label: 'Hogworkz',
    key: 'hogworkz',
  },
  {
    label: 'Honda Collection',
    key: 'honda_collection',
  },
  {
    label: 'Hoppe Industries',
    key: 'hoppe_industries',
  },
  {
    label: 'Hornet Outdoors',
    key: 'hornet_outdoors',
  },
  {
    label: 'Hotbodies Racing',
    key: 'hotbodies_racing',
  },
  {
    label: 'Hot Cams',
    key: 'hot_cams',
  },
  {
    label: 'Hot Rods',
    key: 'hot_rods',
  },
  {
    label: 'Hotwired',
    key: 'hotwired',
  },
  {
    label: 'HyperKewl',
    key: 'hyper_kewl',
  },
  {
    label: 'Icetrekkers',
    key: 'icetrekkers',
  },
  {
    label: 'ICON',
    key: 'icon',
  },
  {
    label: 'IMS',
    key: 'ims',
  },
  {
    label: 'Injector Dynamics',
    key: 'injector_dynamics',
  },
  {
    label: 'INNOVV',
    key: 'innovv',
  },
  {
    label: 'Insta360',
    key: 'insta_360',
  },
  {
    label: 'Intense',
    key: 'intense',
  },
  {
    label: 'Interphone',
    key: 'interphone',
  },
  {
    label: 'IRC Tires',
    key: 'irc_tires',
  },
  {
    label: 'Iron Workers',
    key: 'iron_workers',
  },
  {
    label: 'ITP Tires',
    key: 'itp_tires',
  },
  {
    label: 'Jagg Oil Coolers',
    key: 'jagg_oil_coolers',
  },
  {
    label: 'James Gaskets',
    key: 'james_gaskets',
  },
  {
    label: 'Jammer',
    key: 'jammer',
  },
  {
    label: 'Jensen',
    key: 'jensen',
  },
  {
    label: 'Jims',
    key: 'jims',
  },
  {
    label: 'J&M',
    key: 'j_m',
  },
  {
    label: 'Joe Rocket',
    key: 'joe_rocket',
  },
  {
    label: 'Joker Machine',
    key: 'joker_machine',
  },
  {
    label: 'JT Sprockets',
    key: 'jt_sprockets',
  },
  {
    label: 'J.W. Speaker',
    key: 'j_w_speaker',
  },
  {
    label: 'Kabuto Helmets',
    key: 'kabuto_helmets',
  },
  {
    label: 'Kali Protectives',
    key: 'kali_protectives',
  },
  {
    label: 'Kaoko',
    key: 'kaoko',
  },
  {
    label: 'Kawasaki',
    key: 'kawasaki',
  },
  {
    label: 'Kellermann',
    key: 'kellermann',
  },
  {
    label: 'Kenda Tires',
    key: 'kenda_tires',
  },
  {
    label: "Ken's Factory",
    key: 'kens_factory',
  },
  {
    label: 'Kenwood',
    key: 'kenwood',
  },
  {
    label: 'KFI',
    key: 'kfi',
  },
  {
    label: 'Khrome Werks',
    key: 'khrome_werks',
  },
  {
    label: 'Kicker',
    key: 'kicker',
  },
  {
    label: 'Kimpex',
    key: 'kimpex',
  },
  {
    label: 'Klim',
    key: 'klim',
  },
  {
    label: 'Klock Werks',
    key: 'klock_werks',
  },
  {
    label: 'Klotz Synthetics',
    key: 'klotz_synthetics',
  },
  {
    label: 'K&L Supply',
    key: 'k_l_supply',
  },
  {
    label: 'KMC Powersports',
    key: 'kmc_powersports',
  },
  {
    label: 'K&N Filters',
    key: 'k_n_filters',
  },
  {
    label: 'Knox Armor',
    key: 'knox_armor',
  },
  {
    label: 'Kodlin',
    key: 'kodlin',
  },
  {
    label: 'Kolpin Outdoors',
    key: 'kolpin_outdoors',
  },
  {
    label: 'Koso',
    key: 'koso',
  },
  {
    label: 'Kreem',
    key: 'kreem',
  },
  {
    label: 'Kriega',
    key: 'kriega',
  },
  {
    label: 'Kryptonite',
    key: 'kryptonite',
  },
  {
    label: 'K&S Technologies',
    key: 'k_s_technologies',
  },
  {
    label: 'KST Kustoms',
    key: 'kst_kustoms',
  },
  {
    label: 'K-Tech Suspension',
    key: 'k_tech_suspension',
  },
  {
    label: 'Kuryakyn',
    key: 'kuryakyn',
  },
  {
    label: 'LA Choppers',
    key: 'la_choppers',
  },
  {
    label: 'La Rosa Design',
    key: 'la_rosa_design',
  },
  {
    label: 'LaZer Helmets',
    key: 'la_zer_helmets',
  },
  {
    label: 'LC',
    key: 'lc',
  },
  {
    label: 'Leakproof Seals',
    key: 'leakproof_seals',
  },
  {
    label: 'Leatt',
    key: 'leatt',
  },
  {
    label: 'Lectron Fuel Systems',
    key: 'lectron_fuel_systems',
  },
  {
    label: 'Led Sled',
    key: 'led_sled',
  },
  {
    label: 'Legend Suspension',
    key: 'legend_suspension',
  },
  {
    label: 'LeoVince',
    key: 'leo_vince',
  },
  {
    label: 'Le Pera Seats',
    key: 'le_pera_seats',
  },
  {
    label: 'Lethal Threat',
    key: 'lethal_threat',
  },
  {
    label: 'Letric Lighting Co.',
    key: 'letric_lighting_co',
  },
  {
    label: 'Lexin',
    key: 'lexin',
  },
  {
    label: 'Lezyne',
    key: 'lezyne',
  },
  {
    label: 'LighTech',
    key: 'ligh_tech',
  },
  {
    label: 'LightSpeed',
    key: 'light_speed',
  },
  {
    label: 'Lindby Custom',
    key: 'lindby_custom',
  },
  {
    label: 'Liqui Moly',
    key: 'liqui_moly',
  },
  {
    label: 'Little Hotties',
    key: 'little_hotties',
  },
  {
    label: 'Loctite',
    key: 'loctite',
  },
  {
    label: 'LS2 Helmets',
    key: 'ls_2_helmets',
  },
  {
    label: 'Lyndall Brakes',
    key: 'lyndall_brakes',
  },
  {
    label: 'Machineart Moto',
    key: 'machineart_moto',
  },
  {
    label: 'Magnum Offroad',
    key: 'magnum_offroad',
  },
  {
    label: 'Magura',
    key: 'magura',
  },
  {
    label: 'Maier',
    key: 'maier',
  },
  {
    label: 'Matrix Concepts',
    key: 'matrix_concepts',
  },
  {
    label: 'Maxima Lubricants',
    key: 'maxima_lubricants',
  },
  {
    label: 'Maxxis Tires',
    key: 'maxxis_tires',
  },
  {
    label: 'MBRP',
    key: 'mbrp',
  },
  {
    label: 'Mechanix Wear',
    key: 'mechanix_wear',
  },
  {
    label: 'Megaboost',
    key: 'megaboost',
  },
  {
    label: 'Memphis Shades',
    key: 'memphis_shades',
  },
  {
    label: 'Merlin',
    key: 'merlin',
  },
  {
    label: 'Method Race Wheels',
    key: 'method_race_wheels',
  },
  {
    label: 'Metzeler Tires',
    key: 'metzeler_tires',
  },
  {
    label: 'MFW',
    key: 'mfw',
  },
  {
    label: 'Michelin Tires',
    key: 'michelin_tires',
  },
  {
    label: 'Mika Metals',
    key: 'mika_metals',
  },
  {
    label: 'Milwaukee Twins',
    key: 'milwaukee_twins',
  },
  {
    label: 'Misfit Industries',
    key: 'misfit_industries',
  },
  {
    label: 'Mitas Tires',
    key: 'mitas_tires',
  },
  {
    label: 'Mob Armor',
    key: 'mob_armor',
  },
  {
    label: 'Mobil1',
    key: 'mobil_1',
  },
  {
    label: 'Mobius',
    key: 'mobius',
  },
  {
    label: 'MoDoor',
    key: 'mo_door',
  },
  {
    label: 'Modquad',
    key: 'modquad',
  },
  {
    label: 'Monimoto',
    key: 'monimoto',
  },
  {
    label: 'Moorhead Off-Road Engineering',
    key: 'moorhead_off_road_engineering',
  },
  {
    label: 'Moose Racing',
    key: 'moose_racing',
  },
  {
    label: 'Morimoto',
    key: 'morimoto',
  },
  {
    label: 'Motion Pro',
    key: 'motion_pro',
  },
  {
    label: 'Motodemic',
    key: 'motodemic',
  },
  {
    label: 'Motogadget',
    key: 'motogadget',
  },
  {
    label: 'Moto-Gate',
    key: 'moto_gate',
  },
  {
    label: 'Motonation',
    key: 'motonation',
  },
  {
    label: 'Motopro',
    key: 'motopro',
  },
  {
    label: 'Motorex',
    key: 'motorex',
  },
  {
    label: 'Motorfist',
    key: 'motorfist',
  },
  {
    label: 'Motor Trike',
    key: 'motor_trike',
  },
  {
    label: 'Moto-Skiveez',
    key: 'moto_skiveez',
  },
  {
    label: 'Moto Tassinari',
    key: 'moto_tassinari',
  },
  {
    label: 'Motoz',
    key: 'motoz',
  },
  {
    label: 'Motul',
    key: 'motul',
  },
  {
    label: 'Mountain House',
    key: 'mountain_house',
  },
  {
    label: 'MRA',
    key: 'mra',
  },
  {
    label: 'MSA Offroad',
    key: 'msa_offroad',
  },
  {
    label: 'MSR',
    key: 'msr',
  },
  {
    label: 'MTX Audio',
    key: 'mtx_audio',
  },
  {
    label: 'Muc-Off',
    key: 'muc_off',
  },
  {
    label: 'Mustang Seats',
    key: 'mustang_seats',
  },
  {
    label: 'Nalgene',
    key: 'nalgene',
  },
  {
    label: 'Namz',
    key: 'namz',
  },
  {
    label: 'National Cycle',
    key: 'national_cycle',
  },
  {
    label: 'NavAtlas',
    key: 'nav_atlas',
  },
  {
    label: 'Neken',
    key: 'neken',
  },
  {
    label: 'Nelson Rigg',
    key: 'nelson_rigg',
  },
  {
    label: 'New Rage Cycles',
    key: 'new_rage_cycles',
  },
  {
    label: 'New Ray Toys',
    key: 'new_ray_toys',
  },
  {
    label: 'Nexx Helmets',
    key: 'nexx_helmets',
  },
  {
    label: 'NGK Spark Plugs',
    key: 'ngk_spark_plugs',
  },
  {
    label: 'Nitron Shocks',
    key: 'nitron_shocks',
  },
  {
    label: 'Nitrous Express',
    key: 'nitrous_express',
  },
  {
    label: 'Nolan Helmets',
    key: 'nolan_helmets',
  },
  {
    label: 'No-Mar',
    key: 'no_mar',
  },
  {
    label: 'NoNoise Earplugs',
    key: 'no_noise_earplugs',
  },
  {
    label: 'Norrec Industries',
    key: 'norrec_industries',
  },
  {
    label: 'No Toil',
    key: 'no_toil',
  },
  {
    label: 'NRC Engine Covers',
    key: 'nrc_engine_covers',
  },
  {
    label: 'Oakley',
    key: 'oakley',
  },
  {
    label: 'ODI',
    key: 'odi',
  },
  {
    label: 'OGIO Bags',
    key: 'ogio_bags',
  },
  {
    label: 'Ohlins',
    key: 'ohlins',
  },
  {
    label: 'Olympia',
    key: 'olympia',
  },
  {
    label: "O'Neal Motocross",
    key: 'o_neal_motocross',
  },
  {
    label: 'OneDesign',
    key: 'one_design',
  },
  {
    label: 'One Industries',
    key: 'one_industries',
  },
  {
    label: 'Open Trail',
    key: 'open_trail',
  },
  {
    label: 'Orbit',
    key: 'orbit',
  },
  {
    label: 'Ortovox',
    key: 'ortovox',
  },
  {
    label: 'Other',
    key: 'other',
  },
  {
    label: 'Outerwears',
    key: 'outerwears',
  },
  {
    label: 'Oxford Products',
    key: 'oxford_products',
  },
  {
    label: 'P3',
    key: 'p_3',
  },
  {
    label: 'Pacific Coast Sunglasses',
    key: 'pacific_coast_sunglasses',
  },
  {
    label: 'Pando Moto',
    key: 'pando_moto',
  },
  {
    label: 'Pangor Cycles',
    key: 'pangor_cycles',
  },
  {
    label: 'Panther',
    key: 'panther',
  },
  {
    label: 'Paughco',
    key: 'paughco',
  },
  {
    label: 'Paul Yaffe',
    key: 'paul_yaffe',
  },
  {
    label: 'PCI Race Radios',
    key: 'pci_race_radios',
  },
  {
    label: 'PC Racing',
    key: 'pc_racing',
  },
  {
    label: 'Pedal Commander',
    key: 'pedal_commander',
  },
  {
    label: "Pedro's",
    key: 'pedros',
  },
  {
    label: 'Peregrine Equipment',
    key: 'peregrine_equipment',
  },
  {
    label: 'Performance Machine',
    key: 'performance_machine',
  },
  {
    label: 'Performance Machine Off-Road',
    key: 'performance_machine_off_road',
  },
  {
    label: 'PIAA',
    key: 'piaa',
  },
  {
    label: 'Pingel',
    key: 'pingel',
  },
  {
    label: 'Pirelli Tires',
    key: 'pirelli_tires',
  },
  {
    label: 'Pit Bull Stands',
    key: 'pit_bull_stands',
  },
  {
    label: 'Pivot Pegz',
    key: 'pivot_pegz',
  },
  {
    label: 'Pivot Works',
    key: 'pivot_works',
  },
  {
    label: 'PJ1',
    key: 'pj_1',
  },
  {
    label: 'Plexus',
    key: 'plexus',
  },
  {
    label: 'POD',
    key: 'pod',
  },
  {
    label: 'POD Knee Braces',
    key: 'pod_knee_braces',
  },
  {
    label: 'Polisport',
    key: 'polisport',
  },
  {
    label: 'Powerlet',
    key: 'powerlet',
  },
  {
    label: 'Powerstands',
    key: 'powerstands',
  },
  {
    label: 'PowerTye',
    key: 'power_tye',
  },
  {
    label: 'Preston Petty',
    key: 'preston_petty',
  },
  {
    label: 'Primus',
    key: 'primus',
  },
  {
    label: 'Princeton Tec',
    key: 'princeton_tec',
  },
  {
    label: 'Pro Armor',
    key: 'pro_armor',
  },
  {
    label: 'Pro Circuit',
    key: 'pro_circuit',
  },
  {
    label: 'Progressive Suspension',
    key: 'progressive_suspension',
  },
  {
    label: 'Pro Grip',
    key: 'pro_grip',
  },
  {
    label: 'Project X',
    key: 'project_x',
  },
  {
    label: 'Pro-One',
    key: 'pro_one',
  },
  {
    label: 'Pro Pad',
    key: 'pro_pad',
  },
  {
    label: 'Pro Taper',
    key: 'pro_taper',
  },
  {
    label: 'Pro X',
    key: 'pro_x',
  },
  {
    label: 'Proxxon Tools',
    key: 'proxxon_tools',
  },
  {
    label: 'PRP',
    key: 'prp',
  },
  {
    label: 'Puig',
    key: 'puig',
  },
  {
    label: 'Puma Boots',
    key: 'puma_boots',
  },
  {
    label: 'Python Exhaust',
    key: 'python_exhaust',
  },
  {
    label: 'Quad Boss',
    key: 'quad_boss',
  },
  {
    label: 'Quad Lock',
    key: 'quad_lock',
  },
  {
    label: 'Quad Logic',
    key: 'quad_logic',
  },
  {
    label: 'Quick Strap',
    key: 'quick_strap',
  },
  {
    label: 'Raceline Wheels',
    key: 'raceline_wheels',
  },
  {
    label: 'Race Tech',
    key: 'race_tech',
  },
  {
    label: 'RacingBros',
    key: 'racing_bros',
  },
  {
    label: 'RAM Mounts',
    key: 'ram_mounts',
  },
  {
    label: 'Raptor',
    key: 'raptor',
  },
  {
    label: 'RC Components',
    key: 'rc_components',
  },
  {
    label: 'REAX',
    key: 'reax',
  },
  {
    label: 'REDA Innovations',
    key: 'reda_innovations',
  },
  {
    label: 'Redi Lite',
    key: 'redi_lite',
  },
  {
    label: 'Red Line',
    key: 'red_line',
  },
  {
    label: 'Rekluse',
    key: 'rekluse',
  },
  {
    label: 'Remus Exhaust',
    key: 'remus_exhaust',
  },
  {
    label: 'Renthal',
    key: 'renthal',
  },
  {
    label: 'RevArc',
    key: 'rev_arc',
  },
  {
    label: "REV'IT!",
    key: 'revit',
  },
  {
    label: 'RevZilla',
    key: 'rev_zilla',
  },
  {
    label: 'R&G Racing',
    key: 'r_g_racing',
  },
  {
    label: 'RICHA',
    key: 'richa',
  },
  {
    label: 'RidePower',
    key: 'ride_power',
  },
  {
    label: 'Riding Culture',
    key: 'riding_culture',
  },
  {
    label: 'Rigid',
    key: 'rigid',
  },
  {
    label: 'Rinehart Exhaust',
    key: 'rinehart_exhaust',
  },
  {
    label: 'Risk Racing',
    key: 'risk_racing',
  },
  {
    label: 'Rival Powersports',
    key: 'rival_powersports',
  },
  {
    label: 'Rivco',
    key: 'rivco',
  },
  {
    label: 'Rizoma',
    key: 'rizoma',
  },
  {
    label: 'RK Chain',
    key: 'rk_chain',
  },
  {
    label: 'Rockford Fosgate',
    key: 'rockford_fosgate',
  },
  {
    label: 'Rockymounts',
    key: 'rockymounts',
  },
  {
    label: 'Rokform',
    key: 'rokform',
  },
  {
    label: 'Rokker Jeans',
    key: 'rokker_jeans',
  },
  {
    label: 'ROK Straps',
    key: 'rok_straps',
  },
  {
    label: 'Roland Sands Apparel',
    key: 'roland_sands_apparel',
  },
  {
    label: 'Roland Sands Parts',
    key: 'roland_sands_parts',
  },
  {
    label: 'RotopaX',
    key: 'rotopa_x',
  },
  {
    label: 'Rough Crafts',
    key: 'rough_crafts',
  },
  {
    label: 'Rowe Electronics',
    key: 'rowe_electronics',
  },
  {
    label: 'Rox Risers',
    key: 'rox_risers',
  },
  {
    label: 'Ruby Helmets',
    key: 'ruby_helmets',
  },
  {
    label: 'Rugged Ridge',
    key: 'rugged_ridge',
  },
  {
    label: 'Rukka',
    key: 'rukka',
  },
  {
    label: 'Rumblist Moto Market',
    key: 'rumblist_moto_market'
  },
  {
    label: 'Rush Exhaust',
    key: 'rush_exhaust',
  },
  {
    label: 'Russ Wernimont Designs',
    key: 'russ_wernimont_designs',
  },
  {
    label: 'Ryno Power',
    key: 'ryno_power',
  },
  {
    label: 'RZ Industries',
    key: 'rz_industries',
  },
  {
    label: 'S100',
    key: 's_100',
  },
  {
    label: 'Saddlemen',
    key: 'saddlemen',
  },
  {
    label: 'Saddle Tramp',
    key: 'saddle_tramp',
  },
  {
    label: 'Saint',
    key: 'saint',
  },
  {
    label: 'Saints of Speed',
    key: 'saints_of_speed',
  },
  {
    label: 'Samco',
    key: 'samco',
  },
  {
    label: 'Santoro Fabworx',
    key: 'santoro_fabworx',
  },
  {
    label: 'Sargent Seats',
    key: 'sargent_seats',
  },
  {
    label: 'Sawicki Speed Shop',
    key: 'sawicki_speed_shop',
  },
  {
    label: 'Schampa Technical Wear',
    key: 'schampa_technical_wear',
  },
  {
    label: 'Schott Jackets',
    key: 'schott_jackets',
  },
  {
    label: 'Schuberth Helmets',
    key: 'schuberth_helmets',
  },
  {
    label: 'Scorpio Alarms',
    key: 'scorpio_alarms',
  },
  {
    label: 'Scorpion',
    key: 'scorpion',
  },
  {
    label: 'Scorpion Exhaust',
    key: 'scorpion_exhaust',
  },
  {
    label: 'Scosche',
    key: 'scosche',
  },
  {
    label: 'Scottoiler',
    key: 'scottoiler',
  },
  {
    label: "Scott's Performance",
    key: 'scotts_performance',
  },
  {
    label: 'Scott Sports',
    key: 'scott_sports',
  },
  {
    label: 'SDG',
    key: 'sdg',
  },
  {
    label: 'SDi',
    key: 's_di',
  },
  {
    label: 'SealSavers',
    key: 'seal_savers',
  },
  {
    label: 'Sedici',
    key: 'sedici',
  },
  {
    label: 'Sedona',
    key: 'sedona',
  },
  {
    label: 'Segura',
    key: 'segura',
  },
  {
    label: 'Seizmik',
    key: 'seizmik',
  },
  {
    label: 'Sena Bluetooth',
    key: 'sena_bluetooth',
  },
  {
    label: 'Servo Buddy',
    key: 'servo_buddy',
  },
  {
    label: 'Seven MX',
    key: 'seven_mx',
  },
  {
    label: 'SHAD',
    key: 'shad',
  },
  {
    label: 'Shark Helmets',
    key: 'shark_helmets',
  },
  {
    label: 'Shift Racing',
    key: 'shift_racing',
  },
  {
    label: 'Shinko Tires',
    key: 'shinko_tires',
  },
  {
    label: 'Shoei Helmets',
    key: 'shoei_helmets',
  },
  {
    label: 'Shogun',
    key: 'shogun',
  },
  {
    label: 'Shorai Batteries',
    key: 'shorai_batteries',
  },
  {
    label: 'Shot Race Gear',
    key: 'shot_race_gear',
  },
  {
    label: 'SIDI Boots',
    key: 'sidi_boots',
  },
  {
    label: 'Signal Dynamics',
    key: 'signal_dynamics',
  },
  {
    label: 'Simpson Helmets',
    key: 'simpson_helmets',
  },
  {
    label: 'SKF',
    key: 'skf',
  },
  {
    label: 'Slime',
    key: 'slime',
  },
  {
    label: 'SlyFox Performance',
    key: 'sly_fox_performance',
  },
  {
    label: 'Snorkel Your ATV',
    key: 'snorkel_your_atv',
  },
  {
    label: 'Solight',
    key: 'solight',
  },
  {
    label: 'Spectro Performance Oils',
    key: 'spectro_performance_oils',
  },
  {
    label: 'Speed Bleeder',
    key: 'speed_bleeder',
  },
  {
    label: 'Speed Merchant',
    key: 'speed_merchant',
  },
  {
    label: 'Speedmetal',
    key: 'speedmetal',
  },
  {
    label: 'SpeedStrap',
    key: 'speed_strap',
  },
  {
    label: 'Speed & Strength',
    key: 'speed_strength',
  },
  {
    label: 'Spidi',
    key: 'spidi',
  },
  {
    label: 'Spiegler Brake Lines',
    key: 'spiegler_brake_lines',
  },
  {
    label: 'Spike',
    key: 'spike',
  },
  {
    label: 'SPOT',
    key: 'spot',
  },
  {
    label: 'Spyke',
    key: 'spyke',
  },
  {
    label: 'Spy Optics',
    key: 'spy_optics',
  },
  {
    label: 'S&S Cycle',
    key: 's_s_cycle',
  },
  {
    label: 'S&S Off Road Division',
    key: 's_s_off_road_division',
  },
  {
    label: 'SSV Works',
    key: 'ssv_works',
  },
  {
    label: 'StaCool',
    key: 'sta_cool',
  },
  {
    label: 'Stacyc',
    key: 'stacyc',
  },
  {
    label: 'Stance',
    key: 'stance',
  },
  {
    label: 'Standard Motorcycle Products',
    key: 'standard_motorcycle_products',
  },
  {
    label: 'Stealth Pipes Inc',
    key: 'stealth_pipes_inc',
  },
  {
    label: 'Stockton Tools',
    key: 'stockton_tools',
  },
  {
    label: 'Stompgrip',
    key: 'stompgrip',
  },
  {
    label: 'StreetFX',
    key: 'street_fx',
  },
  {
    label: 'Street & Steel',
    key: 'street_steel',
  },
  {
    label: 'Strider',
    key: 'strider',
  },
  {
    label: 'Stroker Industries',
    key: 'stroker_industries',
  },
  {
    label: 'Stylmartin Boots',
    key: 'stylmartin_boots',
  },
  {
    label: 'Sumax',
    key: 'sumax',
  },
  {
    label: 'Sunstar',
    key: 'sunstar',
  },
  {
    label: 'SUOMY Helmets',
    key: 'suomy_helmets',
  },
  {
    label: 'Super73',
    key: 'super_73',
  },
  {
    label: 'SuperSprox',
    key: 'super_sprox',
  },
  {
    label: 'Supertrapp',
    key: 'supertrapp',
  },
  {
    label: 'Superwinch',
    key: 'superwinch',
  },
  {
    label: 'SW-MOTECH',
    key: 'sw_motech',
  },
  {
    label: 'Symtec',
    key: 'symtec',
  },
  {
    label: 'System 3 Off-Road',
    key: 'system_3_off_road',
  },
  {
    label: 'Talon',
    key: 'talon',
  },
  {
    label: 'TC Bros',
    key: 'tc_bros',
  },
  {
    label: 'TCX Boots',
    key: 'tcx_boots',
  },
  {
    label: 'TechMount',
    key: 'tech_mount',
  },
  {
    label: 'TechSpec',
    key: 'tech_spec',
  },
  {
    label: 'TecMate',
    key: 'tec_mate',
  },
  {
    label: 'Teknic',
    key: 'teknic',
  },
  {
    label: 'Tekvest',
    key: 'tekvest',
  },
  {
    label: 'Tensor Tire',
    key: 'tensor_tire',
  },
  {
    label: 'Thor Motocross',
    key: 'thor_motocross',
  },
  {
    label: 'Thrashin Supply Co',
    key: 'thrashin_supply_co',
  },
  {
    label: 'Thule',
    key: 'thule',
  },
  {
    label: 'Tiger Lights',
    key: 'tiger_lights',
  },
  {
    label: 'Tirox',
    key: 'tirox',
  },
  {
    label: 'TM Designworks',
    key: 'tm_designworks',
  },
  {
    label: 'Tobacco',
    key: 'tobacco',
  },
  {
    label: "Todd's Cycle",
    key: 'todds_cycle',
  },
  {
    label: 'Topeak',
    key: 'topeak',
  },
  {
    label: 'Tour Master',
    key: 'tour_master',
  },
  {
    label: 'Trackside',
    key: 'trackside',
  },
  {
    label: 'Trail Tech',
    key: 'trail_tech',
  },
  {
    label: 'Trask',
    key: 'trask',
  },
  {
    label: 'Travel Chair',
    key: 'travel_chair',
  },
  {
    label: 'Trilobite',
    key: 'trilobite',
  },
  {
    label: 'Trinity Racing',
    key: 'trinity_racing',
  },
  {
    label: 'Triple 9',
    key: 'triple_9',
  },
  {
    label: 'Triumph Motorcycles',
    key: 'triumph_motorcycles',
  },
  {
    label: 'Troy Lee Designs',
    key: 'troy_lee_designs',
  },
  {
    label: 'TRP',
    key: 'trp',
  },
  {
    label: 'Tryonic',
    key: 'tryonic',
  },
  {
    label: 'Tuff Jug',
    key: 'tuff_jug',
  },
  {
    label: 'Twin Air',
    key: 'twin_air',
  },
  {
    label: 'Twin Power',
    key: 'twin_power',
  },
  {
    label: 'Two Brothers',
    key: 'two_brothers',
  },
  {
    label: 'UBCO',
    key: 'ubco',
  },
  {
    label: 'UCLEAR',
    key: 'uclear',
  },
  {
    label: 'Ultimax',
    key: 'ultimax',
  },
  {
    label: 'Uni Filter',
    key: 'uni_filter',
  },
  {
    label: 'Universal Parts Inc.',
    key: 'universal_parts_inc',
  },
  {
    label: 'USWE Hydration',
    key: 'uswe_hydration',
  },
  {
    label: 'UTV Wolfpack',
    key: 'utv_wolfpack',
  },
  {
    label: 'Vance & Hines',
    key: 'vance_hines',
  },
  {
    label: 'Velomacchi',
    key: 'velomacchi',
  },
  {
    label: 'Venture Heat',
    key: 'venture_heat',
  },
  {
    label: 'Vertex',
    key: 'vertex',
  },
  {
    label: 'Vespa',
    key: 'vespa',
  },
  {
    label: 'Vonzipper',
    key: 'vonzipper',
  },
  {
    label: 'Voodoo Exhaust',
    key: 'voodoo_exhaust',
  },
  {
    label: 'Vortex Racing',
    key: 'vortex_racing',
  },
  {
    label: 'VP Racing Fuels',
    key: 'vp_racing_fuels',
  },
  {
    label: 'Warn Industries',
    key: 'warn_industries',
  },
  {
    label: 'Weld Racing',
    key: 'weld_racing',
  },
  {
    label: 'West Eagle',
    key: 'west_eagle',
  },
  {
    label: 'Wet Sounds',
    key: 'wet_sounds',
  },
  {
    label: 'Whip It Light Rods',
    key: 'whip_it_light_rods',
  },
  {
    label: 'Wild 1',
    key: 'wild_1',
  },
  {
    label: 'Wild Ass',
    key: 'wild_ass',
  },
  {
    label: 'Willie & Max',
    key: 'willie_max',
  },
  {
    label: 'Wilwood',
    key: 'wilwood',
  },
  {
    label: 'Wind Vest',
    key: 'wind_vest',
  },
  {
    label: 'Windzone',
    key: 'windzone',
  },
  {
    label: 'Wiseco',
    key: 'wiseco',
  },
  {
    label: 'Wizards',
    key: 'wizards',
  },
  {
    label: 'Wolfman Luggage',
    key: 'wolfman_luggage',
  },
  {
    label: 'Woodcraft',
    key: 'woodcraft',
  },
  {
    label: 'Works Connection',
    key: 'works_connection',
  },
  {
    label: "World's Strongest Coat Hanger",
    key: 'worlds_strongest_coat_hanger',
  },
  {
    label: 'Wrench Rabbit',
    key: 'wrench_rabbit',
  },
  {
    label: 'WRP Racing',
    key: 'wrp_racing',
  },
  {
    label: 'XD Powersports',
    key: 'xd_powersports',
  },
  {
    label: 'XDR',
    key: 'xdr',
  },
  {
    label: 'Xena Intelligent Security',
    key: 'xena_intelligent_security',
  },
  {
    label: 'XKGLOW',
    key: 'xkglow',
  },
  {
    label: 'Xkursion',
    key: 'xkursion',
  },
  {
    label: 'X-Lite Helmets',
    key: 'x_lite_helmets',
  },
  {
    label: 'XTC Power Products',
    key: 'xtc_power_products',
  },
  {
    label: 'XTrig',
    key: 'x_trig',
  },
  {
    label: 'Yamaha',
    key: 'yamaha',
  },
  {
    label: 'Yamaha OEM Parts',
    key: 'yamaha_oem_parts',
  },
  {
    label: 'Yamalube',
    key: 'yamalube',
  },
  {
    label: 'Yana Shiki',
    key: 'yana_shiki',
  },
  {
    label: 'Yoshimura Exhaust',
    key: 'yoshimura_exhaust',
  },
  {
    label: 'Yuasa',
    key: 'yuasa',
  },
  {
    label: 'Z1R Apparel',
    key: 'z_1_r_apparel',
  },
  {
    label: 'Zan Headgear',
    key: 'zan_headgear',
  },
  {
    label: 'ZBroz Racing',
    key: 'z_broz_racing',
  },
  {
    label: 'Zero Gravity',
    key: 'zero_gravity',
  },
  {
    label: 'Zeta',
    key: 'zeta',
  },
  {
    label: 'Zooz',
    key: 'zooz',
  },
];

export const itemConditionOptions = {
  key: 'itemCondition',
  component: 'FieldSelect',
  options: itemConditions,
  label: 'Item Condition',
  mandory: true,
  subCategories: [
    'base_mid_layers',
    'boots',
    'eyewear',
    'gloves',
    'heated_gear',
    'headwear',
    'jackets_vests',
    'jerseys',
    'pants',
    'protection',
    'rain_gear',
    'riding_suits',
    'youth_gear',
    'full_face',
    'modular',
    'dual_sport',
    'dirt',
    'open_face_34',
    'half_helmet',
    'helmet_accessories',
    'air_fuel',
    'audio_speakers',
    'batteries_electrical',
    'bike_protection',
    'body_fairing_fender',
    'brakes',
    'dash_gauges',
    'drive_transmission',
    'exhaust',
    'engine',
    'filters',
    'foot_controls',
    'gas_oil_tanks',
    'handlebars_controls',
    'lighting',
    'mirrors',
    'seats_sissy_bars',
    'suspension_frame',
    'wheel_axle',
    'windshields_windscreens',
    'battery_chargers',
    'bluetooth_communications',
    'books_magazines_media',
    'camping',
    'cleaning_supplies',
    'chain_care',
    'electronics_mounts',
    'gifts',
    'luggage_backpacks',
    'maps',
    'oil_fluids',
    'security',
    'tools',
    'transport_storage',
    'other_accessories',
    'dirtbike_tires',
    'dual_sport_tires',
    'race_tires',
    'supermoto_tires',
    'sport_tires',
    'touring_tires',
    'v_twin_cruiser_tires',
    'vintage_tires',
    'tire_accessories',
    'hats_clothing',
    'jackets_clothing',
    'shirts_clothing',
    'other_casual_clothing',
  ],
};
export const genderOptions = {
  key: 'gender',
  component: 'FieldSelect',
  options: genders,
  label: 'Gender',
  mandory: true,
  subCategories: [
    'base_mid_layers',
    'boots',
    'eyewear',
    'gloves',
    'heated_gear',
    'headwear',
    'jackets_vests',
    'jerseys',
    'pants',
    'rain_gear',
    'riding_suits',
    'youth_gear',
    'full_face',
    'modular',
    'dual_sport',
    'dirt',
    'open_face_34',
    'half_helmet',
    'hats_clothing',
    'jackets_clothing',
    'shirts_clothing',
  ],
};
export const sizeOptions = {
  key: 'size',
  component: 'FieldCheckboxGroup',
  options: sizes,
  label: 'Size',
  mandory: true,
  subCategories: [
    'base_mid_layers',
    'eyewear',
    'gloves',
    'heated_gear',
    'headwear',
    'jackets_vests',
    'jerseys',
    'pants',
    'rain_gear',
    'riding_suits',
    'full_face',
    'modular',
    'dual_sport',
    'dirt',
    'open_face_34',
    'half_helmet',
    'hats_clothing',
    'jackets_clothing',
    'shirts_clothing',
  ],
};
export const shoesSizeOptions = {
  key: 'shoeSize',
  component: 'FieldCheckboxGroup',
  options: shoesSizes,
  label: 'Shoe size',
  mandory: true,
  subCategories: ['boots'],
};
export const yearOptions = {
  key: 'year',
  component: 'FieldTextInput',
  options: {
    min: 1900,
    max: 3000,
  },
  label: 'Year',
  mandory: true,
  subCategories: [
    'adv_vehicles',
    'cafe_vehicles',
    'dirt_vehicles',
    'sport_vehicles',
    'touring_vehicles',
    'v_twin_vehicles',
    'other_vehicles',
  ],
};
export const vehicleOptions = {
  key: 'vehicleCondition',
  component: 'FieldSelect',
  options: vehicleConditions,
  label: 'Vehicle Condition',
  mandory: true,
  subCategories: [
    'adv_vehicles',
    'cafe_vehicles',
    'dirt_vehicles',
    'sport_vehicles',
    'touring_vehicles',
    'v_twin_vehicles',
    'other_vehicles',
  ],
};
export const brandOptions = {
  key: 'brand',
  component: 'FieldSelect',
  options: brands,
  label: 'Brand',
  mandory: false,
};
